import React, { useState } from "react";
import { Flex, Image } from "@chakra-ui/react";
import bg1video from "../../../assets/video/bg1.mp4";
import bg1videoWebm from "../../../assets/video/bg1.webm";
import bg1 from "../../../assets/images/bg1.png"; // Placeholder image

const VideoWrapper = () => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <Flex
      w="100%"
      h="100%"
      overflow="hidden"
      alignItems="center"
      justifyContent="center"
      position="absolute"
    >
      {!isVideoLoaded && (
        <Image
          src={bg1}
          alt="Background placeholder"
          w="100%"
          h="100%"
          objectFit="cover"
          position="absolute"
          zIndex={1}
        />
      )}
      <video
        autoPlay
        loop
        muted
        playsInline
        onCanPlayThrough={() => setIsVideoLoaded(true)}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
      >
        <source src={bg1video} type="video/mp4" />
        <source src={bg1videoWebm} type="video/webm" />
        Your browser does not support the video tag.
      </video>
    </Flex>
  );
};

export default VideoWrapper;
