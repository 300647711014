import React, { useState, useContext, useRef } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
  AbsoluteCenter,
  Divider,
} from "@chakra-ui/react";
import {
  white,
  newBorder,
  dark,
  primary,
  secondary,
  textSecondary,
  textPrimary,
  backgroundLight,
} from "../../../utils/colors";
import { useTranslation } from "react-i18next";
import PasswordInputDefault from "../../../components/input/PasswordInputDefault";
import { motion } from "framer-motion";
import FormControlDefault from "../../../components/input/FormControlDefault";
import FormControlAddressFull from "../../../components/input/FormControlAddressFull";
import FormControlProfessionNew from "../../../components/input/FormControlProfessionNew";
import FormControlCountry from "../../../components/input/FormControlCountry";
import FormControlPhone from "../../../components/input/FormControlPhone";
// import Agreement from "../../../partials/Agreement";
import { Image, Flex } from "@chakra-ui/react";
import mggIcon from "../../../assets/icons/mgg-icon.svg";
import { shade, tint } from "polished";
import { GoogleApiContext } from "../../../contexts/GoogleApiContext";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import FormControlAccountType from "../../../components/input/FormControlAccountType";

const Step1Form = ({
  email,
  password,
  companyName,
  setCompanyName,
  companyCode,
  setCompanyCode,
  address,
  setAddress,
  handleAddressSelect,
  handleAccountTypeClick,
  accountTypes,
  selectedAccountType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  profession,
  handleProfessionChange,
  phone,
  setPhone,
  vatCode,
  setVatCode,
  handleEmailChange,
  handlePasswordChange,
  registerHandler,
  isMobile,
  setSelectedAccountType,
}) => {
  const { t } = useTranslation();
  const { isApiLoaded } = useContext(GoogleApiContext);

  // REF
  const swiperRef = useRef(null);

  // STATE
  const [manualAddressVisible, setManualAddressVisible] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  // TABS
  const slides = [
    {
      title: t("landingMegagen.registerClinic"),
    },
    {
      title: t("landingMegagen.registerDentist"),
    },
    {
      title: t("landingMegagen.registerLab"),
    },
  ];

  // HANDLERS
  const handleAccountTypeAlternative = (type) => {
    setSelectedAccountType(type);
    const index = accountTypes.findIndex((account) => account.type === type);
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index); // Use slideTo method to navigate
    }
  };

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  const toggleManualAddressVisible = () => {
    setManualAddressVisible(!manualAddressVisible);
  };

  // EMAIL VALIDATION
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // VALIDATION
  const isEmailValid = isValidEmail(email);
  const isPasswordValid = password.length >= 1;
  const isCompanyNameValid = companyName.length >= 1;
  const isCompanyCodeValid = companyCode.length >= 1;
  const isVatCodeValid = vatCode.length >= 1;
  const isFirstNameValid = firstName.length >= 1;
  const isLastNameValid = lastName.length >= 1;
  const isProfessionValid = profession.length >= 1;
  const isPhoneValid = phone.length >= 12;

  const isStreetValid = address.street.length >= 3;
  const isZipValid = address.zip.length >= 1;
  const isCityValid = address.city.length >= 2;
  const isCountryValid = address.country.length >= 2;

  const isAddressValid =
    isStreetValid && isZipValid && isCityValid && isCountryValid;

  const isContinueDisabledIndividual =
    !isEmailValid ||
    !isPasswordValid ||
    !isFirstNameValid ||
    !isLastNameValid ||
    !isProfessionValid ||
    !isPhoneValid ||
    !isAddressValid;

  const isContinueDisabledCompany =
    !isEmailValid ||
    !isPasswordValid ||
    !isCompanyNameValid ||
    !isCompanyCodeValid ||
    !isPhoneValid ||
    !isAddressValid;

  console.log("accountTypes", accountTypes);

  return (
    <>
      <Box
        as={motion.div}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
        mt={[0, 0, 12]}
        w="100%"
        bg={white}
        p={["1.5rem 0rem 6rem", "1.5rem 0rem 6rem", "0rem"]}
        // overflow={["auto", "auto", "initial"]}
        // h="100%"
      >
        <FormControl p={["0 1.5rem ", "0 1.5rem ", "0"]}>
          {!isMobile && (
            <FormLabel fontSize="sm" htmlFor="accountType">
              {t("form.accountType")}
            </FormLabel>
          )}

          {!isMobile ? (
            <Grid
              templateColumns="repeat(3, 1fr)"
              gap="1rem"
              m="0 0 1rem"
              w="100%"
            >
              {accountTypes.map((account) => (
                <GridItem colSpan={1} key={account.type}>
                  <Button
                    variant="outline"
                    w="100%"
                    h="3.5rem"
                    borderRadius="1rem"
                    bg={selectedAccountType === account.type ? white : white}
                    fontSize="sm"
                    fontWeight="500"
                    border={`1px solid ${
                      selectedAccountType === account.type ? primary : newBorder
                    }`}
                    color={
                      selectedAccountType === account.type
                        ? textPrimary
                        : textSecondary
                    }
                    _hover={{
                      bg: selectedAccountType === account.type ? white : white,
                      color:
                        selectedAccountType === account.type
                          ? textPrimary
                          : textSecondary,
                      border: `1px solid ${
                        selectedAccountType === account.type
                          ? primary
                          : shade(0.1, newBorder)
                      }`,
                    }}
                    onClick={() => handleAccountTypeClick(account.type)}
                    align="flex-start"
                    justifyContent="flex-start"
                    maxW="220px"
                    minW="220px"
                    whiteSpace="nowrap"
                  >
                    <Flex
                      align="center"
                      justify="center"
                      w="2rem"
                      h="2rem"
                      borderRadius="50%"
                      bg={white}
                      border={`1px solid ${newBorder}`}
                      mr="0.5rem"
                      flexShrink="0"
                    >
                      <Image
                        src={mggIcon}
                        h="1.75rem"
                        filter={
                          selectedAccountType === account.type
                            ? "grayscale(0%)"
                            : "grayscale(100%)"
                        }
                        opacity={selectedAccountType === account.type ? 1 : 0.5}
                      />
                    </Flex>

                    {account.label}
                  </Button>
                </GridItem>
              ))}
            </Grid>
          ) : (
            <>
              <Flex w="100%">
                <FormControlAccountType
                  label={t("form.accountType")}
                  value={selectedAccountType}
                  onChange={(newType) => setSelectedAccountType(newType)}
                  options={accountTypes.map((account) => ({
                    value: account.type,
                    label: account.label,
                  }))}
                  isRequired={true}
                  isTouched={true}
                  isError={!selectedAccountType}
                  errorMessage={t("formError.accountTypeRequired")}
                  w="100%"
                />
              </Flex>
            </>
          )}
        </FormControl>

        <Flex
          gap="1rem"
          direction={["column", "column", "row"]}
          p={["0 1.5rem", "0 1.5rem", "0"]}
        >
          <FormControlDefault
            type="email"
            label={t("form.email")}
            isRequired
            value={email}
            placeholder={t("placeholder.email")}
            onChange={handleEmailChange}
            // onBlur={() => setEmailTouched(true)}
            // isTouched={emailTouched}
            // isError={!isEmailValid}
            w={["100%", "100%", "50%"]}
            errorMessage={t("formError.emailInvalid")}
          />

          <FormControl
            isRequired
            w={["100%", "100%", "50%"]}
            m="0 0 1rem"
            p="0"
          >
            <FormLabel fontSize="sm" htmlFor="password">
              {t("form.password")}
            </FormLabel>
            <PasswordInputDefault
              value={password}
              onChange={handlePasswordChange}
              h="3rem"
              borderRadius="0.75rem"
              fontSize="0.938rem"
            />
          </FormControl>
        </Flex>

        <Box
          position="relative"
          padding={["1.5rem 0 2rem", "1.5rem 0 2rem", "1.5rem 0"]}
        >
          <Divider />
          <AbsoluteCenter bg="white" px={[4, 4, 4]}>
            <Text color={textSecondary} fontSize="xs" whiteSpace="nowrap">
              {selectedAccountType === "clinic" &&
                t("landingMegagen.registerClinicDetails")}
              {selectedAccountType === "dentist" &&
                t("landingMegagen.registerDentistDetails")}
              {selectedAccountType === "lab" &&
                t("landingMegagen.registerLabDetails")}
            </Text>
          </AbsoluteCenter>
        </Box>

        {selectedAccountType !== "dentist" && (
          <>
            <Flex
              gap="1rem"
              direction={["column", "column", "row"]}
              p={["0 1.5rem", "0 1.5rem", "0"]}
            >
              <FormControlDefault
                isRequired
                type="text"
                label={t("form.companyName")}
                id="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder={t("placeholder.companyName")}
                w={["100%", "100%", "50%"]}
              />
              <FormControlPhone
                isRequired
                label={t("form.phone")}
                value={phone}
                onChange={(newPhone) => {
                  setPhone(newPhone);
                }}
                errorMessage={t("formError.phoneRequired")}
                w={["100%", "100%", "50%"]}
                defaultCountry="lt"
              />
            </Flex>

            <Flex
              gap="1rem"
              direction={["column", "column", "row"]}
              p={["0 1.5rem", "0 1.5rem", "0"]}
            >
              <FormControlDefault
                isRequired
                type="text"
                label={t("form.companyCode")}
                id="companyCode"
                value={companyCode}
                onChange={(e) => setCompanyCode(e.target.value)}
                placeholder={t("placeholder.companyCode")}
                w={["100%", "100%", "50%"]}
              />

              <FormControlDefault
                type="text"
                label={t("form.vatCode")}
                id="vatCode"
                value={vatCode}
                onChange={(e) => setVatCode(e.target.value)}
                placeholder={t("placeholder.vatCode")}
                w={["100%", "100%", "50%"]}
                mb={isMobile ? "1rem" : 0}
              />
            </Flex>
          </>
        )}

        {selectedAccountType === "dentist" && (
          <>
            <Flex
              gap="1rem"
              direction={["column", "column", "row"]}
              p={["0 1.5rem", "0 1.5rem", "0"]}
            >
              <FormControlDefault
                isRequired
                type="text"
                label={t("form.firstName")}
                id="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t("placeholder.firstName")}
                w="100%"
              />

              <FormControlDefault
                isRequired
                type="text"
                label={t("form.lastName")}
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t("placeholder.lastName")}
                w="100%"
                mb={isMobile ? "1rem" : 0}
              />
            </Flex>

            <Flex
              gap="1rem"
              direction={["column", "column", "row"]}
              p={["0 1.5rem", "0 1.5rem", "0"]}
            >
              <FormControlProfessionNew
                isRequired
                label={t("form.profession")}
                value={profession}
                placeholder={t("select.profession")}
                onChange={handleProfessionChange}
                w={["100%", "100%", "50%"]}
                onProfessionChange={handleProfessionChange}
              />
              <FormControlPhone
                isRequired
                label={t("form.phone")}
                value={phone}
                onChange={(newPhone) => {
                  setPhone(newPhone);
                }}
                errorMessage={t("formError.phoneRequired")}
                w={["100%", "100%", "50%"]}
                defaultCountry="lt"
              />
            </Flex>
          </>
        )}

        {!manualAddressVisible && (
          <Flex direction="column" w="100%" p={["0 1.5rem", "0 1.5rem", "0"]}>
            {isApiLoaded && (
              <FormControlAddressFull
                isRequired
                label={t("form.workAddress")}
                placeholder={t("placeholder.googleAddress")}
                onAddressSelect={handleAddressSelect}
                currentValue={address.display}
                w="100%"
                isApiLoaded={isApiLoaded}
              />
            )}
            <Flex align="center" justify="center" gap="1rem" p="0.5rem 0 0">
              <Flex
                position="relative"
                m="0rem 0 0"
                justify="flex-end"
                w="100%"
              >
                <Button
                  bg="rgba(255,255,255,0.3)"
                  onClick={toggleManualAddressVisible}
                  color={primary}
                  variant="ghost"
                  fontSize="xs"
                  size="xs"
                  fontWeight="500"
                  p="0.25rem 0.75rem"
                  borderRadius="10rem"
                  opacity="0.8"
                  transition="all 0.3s"
                  _hover={{
                    // bg: backgroundLight,
                    opacity: "1",
                  }}
                >
                  {t("onboarding.enterAddressManual")}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )}

        {manualAddressVisible && (
          <>
            <Flex
              direction="column"
              align="center"
              justify="center"
              p={["0 1.5rem", "0 1.5rem", "0"]}
            >
              <HStack gap={4} w="100%">
                <FormControlDefault
                  isRequired
                  type="text"
                  label={t("form.street")}
                  value={address.street}
                  placeholder={t("placeholder.street")}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      street: e.target.value,
                    })
                  }
                  w={["100%", "50%", "50%"]}
                />

                <FormControlDefault
                  isRequired
                  type="text"
                  label={t("form.zip")}
                  value={address.zip}
                  placeholder={t("placeholder.zip")}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      zip: e.target.value,
                    })
                  }
                  w={["100%", "50%", "50%"]}
                />
              </HStack>

              <HStack gap={4} w="100%" m={["1rem 0 0", "1rem 0 0", "initial"]}>
                <FormControlDefault
                  isRequired
                  type="text"
                  label={t("form.city")}
                  value={address.city}
                  placeholder={t("placeholder.city")}
                  onChange={(e) =>
                    setAddress({
                      ...address,
                      city: e.target.value,
                    })
                  }
                  w={["100%", "50%", "50%"]}
                  errorMessage={t("formError.cityRequired")}
                />

                <FormControlCountry
                  isRequired
                  label={t("form.country")}
                  value={address.country}
                  onChange={(selectedOption) => {
                    setAddress(
                      Object.assign({}, address, {
                        country: selectedOption.value,
                      })
                    );
                  }}
                  w={["100%", "50%", "50%"]}
                  errorMessage={t("formError.countryRequired")}
                />
              </HStack>
              <Flex align="center" justify="center" gap="1rem">
                <Flex position="relative" m="0rem 0 0" justify="center">
                  <Button
                    bg="rgba(255,255,255,0.3)"
                    onClick={toggleManualAddressVisible}
                    color={primary}
                    variant="link"
                    fontSize="xs"
                    fontWeight="500"
                    p="0.5rem 0.75rem"
                    borderRadius="10rem"
                    _hover={{
                      bg: "rgba(255,255,255,1)",
                    }}
                  >
                    {t("onboarding.enterAddressGoogle")}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}

        <Flex
          w="100%"
          align="cemter"
          justify="flex-end"
          gap="1rem"
          p={["3rem 1.5rem 0", "3rem 1.5rem 0", "3rem 0 0rem"]}
          direction={["column-reverse", "column-reverse", "row"]}
        >
          <Flex align="center" justify="flex-start" m="0 auto">
            <Text
              fontSize="xs"
              color={textSecondary}
              textAlign="center"
              fontWeight="500"
              maxW={["100%", "100%", "300px"]}
              m="0 auto"
            >
              {t("common.acceptTerms")}{" "}
              {/* <Link to="/terms" isDisabled>
                <Text as="span" color={textPrimary}>
                  {" "}
                  {t("common.acceptTermsOfService")}
                </Text>
              </Link>{" "} */}
              <Text
                as="span"
                color={textPrimary}
                isDisabled
                opacity="0.8"
                transition="all 0.3s"
                _hover={{
                  cursor: "not-allowed",
                  opacity: 0.8,
                }}
              >
                {" "}
                {t("common.acceptTermsOfService")}{" "}
              </Text>
              {t("common.and")}{" "}
              {/* <Link to="/terms" isDisabled>
                <Text as="span" color={textPrimary}>
                  {" "}
                  {t("common.privacyPolicy")}
                </Text>
              </Link> */}
              <Text
                as="span"
                color={textPrimary}
                isDisabled
                opacity="0.8"
                transition="all 0.3s"
                _hover={{
                  cursor: "not-allowed",
                  opacity: 0.8,
                }}
              >
                {t("common.privacyPolicy")}
              </Text>
              .
            </Text>
          </Flex>
          <Button
            type="submit"
            h="3.5rem"
            borderRadius="10rem"
            bg={primary}
            color={white}
            border={`1px solid ${dark}`}
            textAlign="center"
            px="6rem"
            w={["100%", "100%", "initial"]}
            _hover={{
              bg: shade(0.1, primary),
              color: white,
            }}
            onClick={registerHandler}
            isDisabled={
              selectedAccountType === "dentist"
                ? isContinueDisabledIndividual
                : isContinueDisabledCompany
            }
          >
            {t("common.register")}
          </Button>
        </Flex>

        {/* <Agreement /> */}
      </Box>
    </>
  );
};

export default Step1Form;
