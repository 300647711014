import axios from "axios";
import React, { useState } from "react";
import {
  Box,
  Image,
  Spinner,
  Stack,
  useToast,
  Text,
  Flex,
  HStack,
  Button,
} from "@chakra-ui/react";
import { icons } from "../../utils/icons";
import styled from "@emotion/styled";
import {
  backgroundLight,
  newBorder,
  textSecondary,
  textPrimary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";

const ImageUpload = ({ currentImage, image, setImage }) => {
  const [uploading, setUploading] = useState(false);
  const toast = useToast();
  const { t } = useTranslation();

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    setUploading(true);

    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data } = await axios.post("/api/upload", formData, config);

      setImage(data);
      setUploading(false);

      // Display success toast
      toast({
        title: t("imageUpload.successTitle"),
        // description: t("imageUpload.successDescription"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error);
      setUploading(false);

      // Display error toast
      toast({
        title: t("imageUpload.errorTitle"),
        // description: t("imageUpload.errorDescription"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleRemoveImage = () => {
    setImage(null);
    toast({
      title: t("imageUpload.removeSuccess"),
      status: "info",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Flex
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border={`1px dashed ${newBorder}`}
      borderRadius="0.75rem"
      p={["1rem", "1rem", 4]}
      direction={["column", "column", "row"]}
    >
      <Flex
        gap={0}
        direction={["column", "column", "row"]}
        w={["100%", "100%", "initial"]}
      >
        <Box
          w={["100%", "100%", "100px"]}
          h={["200px", "200px", "100px"]}
          borderRadius="0.75rem"
          overflow="hidden"
          bg={backgroundLight}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexSHrink="0"
          onClick={() => {
            document.getElementById("file-upload").click();
          }}
          _hover={{
            cursor: "pointer",
          }}
        >
          {image ? (
            <Image
              src={currentImage || image || icons.noImage}
              objectFit="cover"
              h="100%"
              w="100%"
            />
          ) : (
            <Image
              h="1.5rem"
              w="1.5rem"
              src={icons.noImage}
              transition="all 0.2s ease-in-out"
              _hover={{ transform: "scale(1.2)" }}
            />
          )}

          {uploading && <Spinner />}
        </Box>

        <Stack pl={4} spacing={0} m={["1.5rem 0 1rem", "1.5rem 0 1rem", 0]}>
          <Text color={textPrimary} fontSize="1rem" fontWeight="500">
            {image ? t("imageUpload.changeImage") : t("imageUpload.title")}
          </Text>
          <Text color={textSecondary} fontSize="sm" fontWeight="400">
            {image ? image : t("imageUpload.description")}
          </Text>
        </Stack>
      </Flex>

      <HStack w={["100%", "100%", "initial"]}>
        <UploadButton htmlFor="file-upload" className="custom-file-upload">
          <Icon2 src={icons.upload} />
          {image ? t("imageUpload.change") : t("imageUpload.browse")}
        </UploadButton>

        <input
          id="file-upload"
          type="file"
          onChange={uploadFileHandler}
          style={{ display: "none" }}
        />
      </HStack>
    </Flex>
  );
};
const Icon2 = styled.img`
  height: 14px;
  margin-right: 8px;
`;

const UploadButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  padding: 12px 1.5rem;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
  background: ${backgroundLight};
  color: ${textSecondary};
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #ddd;
    color: #000;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export default ImageUpload;
