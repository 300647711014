import React, { useEffect } from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import {
  newBorder,
  white,
  primary,
  textPrimary,
  textSecondary,
} from "../../utils/colors";

const FormControlTimezone = ({
  label,
  value,
  placeholder,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
}) => {
  const { t } = useTranslation();

  // Generate timezone options with GMT offsets
  const timezoneOptions = moment.tz.names().map((zone) => {
    const offset = moment.tz(zone).utcOffset() / 60;
    const sign = offset >= 0 ? "+" : "-";
    const formattedOffset = `GMT${sign}${Math.abs(offset)}`;
    return {
      value: `${zone}`,
      label: `${zone} (${formattedOffset})`,
    };
  });

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: "0.938rem",
      fontWeight: "500",
      minHeight: "2.5rem",
      height: "3rem",
      padding: "0 0.25rem",
      ":focus-visible": {
        borderColor: primary,
        boxShadow: "none",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: textPrimary,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : white,
      color: state.isFocused ? white : textSecondary,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "1rem",
      border: `0px solid ${newBorder}`,
      backgroundColor: white,
      zIndex: 10,
    }),
  };

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize="sm">
        {label}
        {isError && isTouched && (
          <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={timezoneOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={timezoneOptions}
        placeholder={placeholder || t("Select timezone")}
        styles={customStyles}
      />
    </FormControl>
  );
};

export default FormControlTimezone;
