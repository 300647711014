import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/general/Login";
import NotFound from "../pages/general/NotFound";
import Landing from "../pages/landing/Landing";
import CoursesPublic from "../pages/public/courses/CoursesPublic";
import CourseSinglePublic from "../pages/public/courses/single/CourseSinglePublic";
import Register from "../pages/general/Register";
import ImplantFailForm from "../pages/client/implant-fail-form/ImplantFailForm/ImplantFailForm";
import VerifyRegistration from "../pages/general/VerifyRegistration";
import UserAccount from "../pages/client/account/UserAccount";
import VerifyPublicRegisterToken from "../pages/public/courses/VerifyPublicRegisterToken";
import Clients from "../pages/admin/clients/list/Clients";
import ForgotPassword from "../pages/general/ForgotPassword";
import ResetPassword from "../pages/general/ResetPassword";
import Offers from "../pages/public/custom/offers/Offers";

// const PublicLayout = ({ children }) => {
//   return (
//     <Flex direction="column">
//       <Header />
//       {children}
//     </Flex>
//   );
// };

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verify-token" element={<VerifyRegistration />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/verify-event-register"
        element={<VerifyPublicRegisterToken />}
      />
      <Route path="/implant-fail-form" element={<ImplantFailForm />} />
      <Route path="/events" element={<CoursesPublic />} />
      <Route path="/events/:id" element={<CourseSinglePublic />} />
      <Route path="/account" element={<UserAccount />} />
      <Route path="/admin/clients" element={<Clients />} />
      <Route path="/offers" element={<Offers />} />

      {/* Other routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default PublicRoutes;
