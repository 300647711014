// src/utils/apiClient.js
import axios from "axios";
import store from "../store";
import { logout } from "../actions/userActions";

const apiClient = axios.create({
  baseURL: "/api", // Base URL for your API endpoints
});

// Request interceptor to add the token to headers
apiClient.interceptors.request.use((config) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  if (userInfo && userInfo.token) {
    config.headers.Authorization = `Bearer ${userInfo.token}`;
  }
  return config;
});

// Response interceptor to handle errors globally
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Check for 401 Unauthorized error (token failure)
    const isUnauthorized = error.response && error.response.status === 401;

    if (
      isUnauthorized &&
      (error.response.data.message === "Not authorized, token failed" ||
        error.response.data.message === "Token version mismatch")
    ) {
      // Dispatch logout action to clear user data and redirect
      store.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

export default apiClient;
