import React from "react";
import { Helmet } from "react-helmet";
import {
  Flex,
  Heading,
  Image,
  Grid,
  GridItem,
  useToast,
} from "@chakra-ui/react";
import Header from "../../../landing/layout/Header";
import { useTranslation } from "react-i18next";
import Footer from "../../../landing/layout/Footer";
import { useMediaQuery } from "react-responsive";
import { backgroundLight, newBorder, white } from "../../../../utils/colors";
import promo1 from "../../../../assets/images/promo/1.png";
import promo2 from "../../../../assets/images/promo/2.png";
import promo3 from "../../../../assets/images/promo/3.png";
import promo4 from "../../../../assets/images/promo/4.png";
import promo5 from "../../../../assets/images/promo/5.png";
import promo6 from "../../../../assets/images/promo/6.png";
import promo7 from "../../../../assets/images/promo/7.png";
import promo8 from "../../../../assets/images/promo/8.png";

const Offers = () => {
  // GENERAL
  const { t } = useTranslation();
  const toast = useToast();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // IMAGES
  const promoImages = [
    promo1,
    promo2,
    promo3,
    promo4,
    promo5,
    promo6,
    promo7,
    promo8,
  ];

  //RENDER
  return (
    <>
      <Helmet>
        <title>{t("pageTitle.offers")}</title>
        <meta name="description" content={t("pageContent.offers")} />
      </Helmet>

      <Header />
      <Flex w="100%" direction="column" p="100px 0 0" bg={white}>
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["0 0rem", "0 0rem", "0 0rem"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 1rem", "0 0 1rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "0"]}
          >
            {t("landingMegagen.menuDiscounts")}
          </Heading>
        </Flex>

        <Flex
          w="100% "
          bg={backgroundLight}
          m={["0rem 0 0", "0rem 0 0", "3rem 0 0"]}
          p={["1.5rem 0", "1.5rem 0", "3rem 0 4rem"]}
        >
          <Flex
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            p={["0 0rem", "0 0rem", "0 0rem"]}
            direction="column"
            m={["0rem auto 0", "0rem auto 0", "0rem auto 0"]}
            align="center"
            justify="center"
            // borderBottom={`1px soild ${newBorder}`}
          >
            <Grid
              templateColumns={[
                "repeat(1, 1fr)",
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
              ]}
              gap="1rem"
              w="100%"
            >
              {promoImages.map((image, index) => (
                <GridItem key={index}>
                  <Image
                    src={image}
                    alt={`Promo ${index + 1}`}
                    w="100%"
                    // h="300px"
                    objectFit="cover"
                  />
                </GridItem>
              ))}
            </Grid>
          </Flex>
        </Flex>

        <Footer />
      </Flex>
    </>
  );
};

export default Offers;
