import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Spinner,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  useToast,
  Icon,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
  buttonRed,
  buttonRedText,
} from "../../../../../utils/colors";
import { useTranslation } from "react-i18next";
import StatusButton from "../../../../../components/buttons/StatusButton";
import Header from "../../../../landing/layout/Header";
import AccountTabs from "../../partials/AccountTabs";
import Faq from "../../../../general/partials/Faq";
import Footer from "../../../../landing/layout/Footer";
import SinglePageTopBar from "../../../../../components/layout/SinglePageTopBar";
import { getUserSingleEvent } from "../../../../../actions/userActions";
import UserCourseInfo from "./partials/UserCourseInfo";
import ConfirmCancelModalEvent from "../../../../../components/modals/ConfirmCancelModalEvent";
import { useMediaQuery } from "react-responsive";
import { updateUserRegisteredCoursesPublicStatus } from "../../../../../actions/userActions";
import { USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_RESET } from "../../../../../constants/userConstants";
import dayjs from "dayjs";
import { FaArrowLeft } from "react-icons/fa6";

const UserSingleEvent = () => {
  // ID
  const id = useParams().id;

  // MOBILE
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  // GENERAL
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // STATE
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  // STATE

  const [eventInfo, setEventInfo] = useState({});
  // const [attendeeInfo, setAttendeeInfo] = useState({});
  const [attendeeId, setAttendeeId] = useState("");

  // REDUX
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  console.log("userInfo", userInfo);

  const userGetSingleEvent = useSelector((state) => state.userGetSingleEvent);
  const { loading, error, course } = userGetSingleEvent;

  const userRegisteredCoursesPublicUpdateStatus = useSelector(
    (state) => state.userRegisteredCoursesPublicUpdateStatus
  );

  const {
    loading: loadingUpdateStatus,
    error: errorUpdateStatus,
    success: successUpdateStatus,
  } = userRegisteredCoursesPublicUpdateStatus;

  // HANDLERS
  const cancelReportHandler = () => {
    dispatch(
      updateUserRegisteredCoursesPublicStatus(
        userInfo._id,
        id,
        "cancelled",
        cancelReason,
        attendeeId
      )
    );
  };

  // USE EFFECT
  useEffect(() => {
    if (userInfo && userInfo._id) {
      dispatch(getUserSingleEvent(userInfo._id, id));
    }
  }, [dispatch, userInfo, successUpdateStatus, errorUpdateStatus, id]);

  // useEffect(() => {
  //   if (course) {
  //     setEventInfo(course);
  //   } else {
  //     setEventInfo({});
  //   }
  // }, [course]);

  useEffect(() => {
    if (course && course.attendee) {
      setEventInfo(course);
      setAttendeeId(course.attendee._id);
    } else {
      setEventInfo({});
      setAttendeeId("");
    }
  }, [course]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    if (successUpdateStatus) {
      toast({
        title: t("toasts.eventUserCancelSuccess"),
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } else if (errorUpdateStatus) {
      toast({
        title: t("toasts.eventUserCancelError"),
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }

    dispatch({ type: USER_REGISTERED_COURSES_PUBLIC_UPDATE_STATUS_RESET });
  }, [successUpdateStatus, errorUpdateStatus]);

  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  // TABS

  let tabs = [
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab0Alt")
        : t("landingMegagen.userAccountTab0"),
      disabled: false,
      path: "/account",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab1Alt")
        : t("landingMegagen.userAccountTab1"),
      disabled: false,
      path: "/account/forms",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab2Alt")
        : t("landingMegagen.userAccountTab2"),
      disabled: false,
      path: "/account/events",
    },
    {
      title: isMobile
        ? t("landingMegagen.userAccountTab3Alt")
        : t("landingMegagen.userAccountTab3"),
      disabled: false,
      path: "/account/employees",
    },
  ];

  if (userInfo && userInfo.clientType === "individual") {
    tabs = tabs.filter((tab, index) => index !== 3); // Remove the fourth tab if clientType is 'individual'
  }

  // CUSTOM

  // LOGS

  // console.log("tabIndex", tabIndex);
  console.log("userInfo", userInfo);
  console.log("id", id);
  // console.log("event", event);
  // console.log("attendee", attendee);
  console.log("eventInfo", eventInfo);
  // console.log("attendeeInfo", attendeeInfo);
  console.log("attendeeId", attendeeId);
  // console.log("cancelReason", cancelReason);

  // console.log("userInfo._id", userInfo._id);
  // console.log("id", id);
  // console.log("cancelReason", cancelReason);

  console.log("course", course);

  return (
    <>
      <Helmet>
        <title>{t("pageTitle.userAccountEventsSingle")}</title>
        <meta
          name="description"
          content={t("pageDescription.userAccountEventsSingle")}
        />
      </Helmet>

      {showCancelModal && (
        <ConfirmCancelModalEvent
          course={course}
          // attendee={attendeeInfo}
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          confirmAction={() => {
            cancelReportHandler();
            setShowCancelModal(false);
          }}
          cancelReason={cancelReason}
          setCancelReason={setCancelReason}
        />
      )}

      <Header />

      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "1.5rem 0 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 0rem", "0 0 0rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("landingMegagen.userAccountTitle")}
          </Heading>
        </Flex>

        <AccountTabs tabs={tabs} fixedTabIndex={2} />

        <Flex
          w="100%"
          justify="center"
          gap="2rem"
          p="3rem 0 0"
          bg={backgroundLight}
        >
          <Flex
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            p="0 1rem 6rem"
            direction="column"
            m="0rem auto 0"
            minH="80vh"
          >
            {!loading ? (
              <>
                {isMobile && (
                  <Flex w="100%" p="0 0rem">
                    <Button
                      size={["sm", "sm", "sm"]}
                      fontSize="sm"
                      borderRadius="10rem"
                      fontWeight="500"
                      role="group"
                      color={textSecondary}
                      bg={["transparent", "transparent", "transparent"]}
                      p={["initial", "initial", "0.5rem 1rem"]}
                      m="0 0 1rem"
                      _hover={{
                        bg: "transparent",
                        color: textPrimary,
                      }}
                      onClick={() => navigate("/account/events")}
                    >
                      <Icon
                        as={FaArrowLeft}
                        fontSize="0.75rem"
                        mr="0.5rem"
                        _groupHover={{
                          color: textPrimary,
                        }}
                      />
                      {t("clientAccount.backToMyEvents")}
                    </Button>
                  </Flex>
                )}

                <Flex
                  w="100%"
                  bg={white}
                  direction="column"
                  borderRadius="2rem"
                >
                  {!isMobile && (
                    <SinglePageTopBar
                      backFunction={() => navigate(-1)}
                      title={
                        t("clientAccount.singleEvent") + " - " + course &&
                        course.course &&
                        course.course.title &&
                        course.course.title
                      }
                      type={`${t("attendee.attendee")}: ${
                        course &&
                        course.attendeeFirstName &&
                        course.attendeeFirstName[0]
                      }. ${
                        course &&
                        course.attendeeLastName &&
                        course.attendeeLastName
                      }`}
                      //   loading={loading}
                    >
                      <StatusButton
                        isUnclickable
                        currentStatus={course && course.status}
                        //  onUpdateStatus={handleUpdateStatus}
                        options="attendees"
                      />
                    </SinglePageTopBar>
                  )}

                  {!loading ? (
                    <>
                      <Flex direction="column" w="100%">
                        <Flex
                          gap={0}
                          p={[
                            "1.5rem",
                            "1.5rem",
                            "2.5rem 2.5rem 1.5rem 2.5rem",
                          ]}
                          alignItems="flex-start"
                          justifyContent="center"
                          w="100%"
                        >
                          <Flex direction="column" w="100%">
                            <UserCourseInfo
                              course={course}
                              // attendee={attendeeInfo}
                            />
                            {/* <ReportInfo data={event} /> */}
                          </Flex>
                          {/* <SinglePageImageWrapper
                    id={id}
                    borderRadius="2rem"
                    // image={course.image}
                    options="course"
                  /> */}
                        </Flex>

                        <Flex
                          w="100%"
                          p={[
                            "0rem 2rem 3rem",
                            "0rem 2rem 3rem",
                            "0rem 2.5rem 3rem",
                          ]}
                          direction="column"
                          m={["3rem 0 0", "3rem 0 0", "initial"]}
                        >
                          <Flex
                            w="100%"
                            align="center"
                            justify="space-between"
                            m={["0rem 0 1rem", "0rem 0 1rem", "initial"]}
                          >
                            <Heading
                              fontSize="md"
                              fontWeight="600"
                              color={textPrimary}
                              m={[
                                "0rem 0 0rem",
                                "0rem 0 0rem",
                                "0rem 0 1.5rem",
                              ]}
                            >
                              {t("clientAccount.attendeeStatus")}
                            </Heading>

                            <Flex display={["flex", "flex", "none"]}>
                              <StatusButton
                                isUnclickable
                                currentStatus={course && course.status}
                                //  onUpdateStatus={handleUpdateStatus}
                                options="attendees"
                              />
                            </Flex>
                          </Flex>

                          <Flex
                            border={`1px solid ${newBorder}`}
                            borderRadius="1rem"
                            w="100%"
                            p="2rem 2rem"
                          >
                            {course && course.status === "cancelled" && (
                              <Flex
                                w="100%"
                                justify="center"
                                align="center"
                                direction={["column", "column", "row"]}
                              >
                                <Stack
                                  gap="0.25rem"
                                  maxW="100%"
                                  justify="center"
                                  align="center"
                                  textAlign="center"
                                >
                                  <StatusButton
                                    isUnclickable
                                    currentStatus={course.status}
                                    //  onUpdateStatus={handleUpdateStatus}
                                    options="report"
                                  />
                                  <Text
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textSecondary}
                                    maxW="100%"
                                    m="1rem 0 0"
                                  >
                                    {t("clientAccount.cancelledEventText")} -
                                    <Text
                                      as="span"
                                      color={textPrimary}
                                      fontWeight="500"
                                      ml={1}
                                      textTransform="capitalize"
                                    >
                                      {dayjs(course.cancelledAt).format(
                                        "D MMMM YYYY, HH:mm"
                                      )}
                                    </Text>
                                  </Text>
                                  <Text
                                    fontSize="sm"
                                    fontWeight="500"
                                    color={textSecondary}
                                    maxW="100%"
                                  >
                                    {t("implantFailureReports.cancelReason")} -
                                    <Text
                                      as="span"
                                      color={textPrimary}
                                      fontWeight="500"
                                      ml={1}
                                      textTransform="capitalize"
                                    >
                                      {course.cancelReason}
                                    </Text>
                                  </Text>
                                </Stack>
                              </Flex>
                            )}

                            {course && course.status !== "cancelled" && (
                              <Flex
                                align="center"
                                justify="space-between"
                                w="100%"
                                gap="2rem"
                                direction={["column", "column", "row"]}
                              >
                                <Text
                                  fontSize="sm"
                                  fontWeight="500"
                                  color={textSecondary}
                                  maxW={["100%", "100%", "50%"]}
                                >
                                  {t("clientAccount.eventActionsText")}
                                </Text>

                                <Flex
                                  align="center"
                                  justify="flex-end"
                                  gap="1.5rem"
                                >
                                  <Button
                                    size="lg"
                                    borderRadius="10rem"
                                    bg={white}
                                    color={buttonRedText}
                                    fontSize="sm"
                                    border={`1px solid ${buttonRedText}`}
                                    // fontWeight="500"
                                    onClick={() => setShowCancelModal(true)}
                                    _hover={{
                                      bg: buttonRed,
                                    }}
                                  >
                                    {t("clientAccount.cancelEvent")}
                                  </Button>
                                </Flex>
                              </Flex>
                            )}
                          </Flex>
                        </Flex>
                      </Flex>
                    </>
                  ) : (
                    <Flex
                      justify="center"
                      align="center"
                      w="100%"
                      p="8rem 2rem"
                    >
                      <Spinner size="xl" color={primary} />
                    </Flex>
                  )}
                </Flex>
              </>
            ) : (
              <Flex
                w="100%"
                bg={white}
                direction="column"
                borderRadius="2rem"
                align="center"
                justify="center"
                minH="60vh"
              >
                <Spinner size="xl" color={primary} />
              </Flex>
            )}
          </Flex>
        </Flex>

        <Flex
          bg={white}
          borderTop={`1px solid ${newBorder}`}
          w="100%"
          p="3rem 0"
          borderBottom={`1px solid ${newBorder}`}
        >
          <Flex w="100%" maxW="1200px" m="0 auto">
            <Faq />
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default UserSingleEvent;
