import React, { useState } from "react";
import {
  Text,
  Image,
  HStack,
  Stack,
  Avatar,
  Flex,
  Tooltip,
  Box,
  Input,
  Icon,
  Button,
} from "@chakra-ui/react";
import {
  textSecondary,
  textPrimary,
  greenVivid,
  buttonRed,
  buttonRedText,
  backgroundLight,
  newBorder,
  white,
} from "../../../../utils/colors";
import MoreButton from "../../../../components/buttons/MoreButton";
// import StatusButtonAttendees from '../../../../components/buttons/StatusButtonAttendees';
import StatusButton from "../../../../components/buttons/StatusButton";
import ToggleButton from "../../../../components/buttons/ToggleButton";
import CoursePriceButton from "../../../../components/buttons/CoursePriceButton";
import PricePopover from "../../../../components/popovers/PricePopover";
import dayjs from "dayjs";
import { FaUser, FaSuitcase } from "react-icons/fa";
import { FaRegCircleCheck, FaRegCircleXmark } from "react-icons/fa6";
import { shade } from "polished";
import { TbUser } from "react-icons/tb";

// CUSTOM
const TooltipEntry = ({ label, icon, capitalize, text, maxW }) => {
  return (
    <Flex align="center">
      <Flex
        w="1.25rem"
        h="1.25rem"
        align="center"
        justify="center"
        bg={white}
        mr={2}
        borderRadius="50%"
        flexShrink="0"
      >
        <Icon as={icon} fontSize="0.875rem" color={textSecondary} />
      </Flex>
      {text && (
        <Text
          fontSize="sm"
          fontWeight="500"
          textTransform={capitalize ? "capitalize" : "none"}
          opacity={0.6}
          mr={1}
          whiteSpace="nowrap"
        >
          {text}:
        </Text>
      )}

      <Text
        fontSize="sm"
        fontWeight="500"
        textTransform={capitalize ? "capitalize" : "none"}
        maxW={maxW}
        isTruncated={maxW ? true : false}
      >
        {label}
      </Text>
    </Flex>
  );
};

export const columns = (
  translate,
  removeAttendeeHandler,
  removeAttendeeHandlerPublic,
  updateCourseAttendeeStatusHandler,
  updateCourseAttendeePaidStatusHandler,
  handleSortChange,
  updateCourseAttendeePriceHandler,
  isOpen,
  setIsOpen
) => {
  return [
    {
      Header: () => (
        <Box onClick={() => handleSortChange("customId")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.customId")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "customId",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { registerDate } = row.original;
        // console.log("row", row.original);

        const currentYear = dayjs().year();
        const registerYear = dayjs(registerDate).year();

        return (
          <>
            <Stack gap={0}>
              <Text
                maxWidth="140px"
                overflow="hidden"
                whiteSpace="nowrap"
                fontSize="13px"
                color={textPrimary}
                fontWeight="500"
                textTransform="capitalize"
                w="80px"
              >
                {dayjs(registerDate).format("D MMMM")}
              </Text>
              <Text
                maxWidth="140px"
                overflow="hidden"
                whiteSpace="nowrap"
                fontSize="xs"
                color={textSecondary}
                fontWeight="500"
                w="80px"
              >
                {dayjs(registerDate).format("YYYY")}
              </Text>
            </Stack>
          </>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("lastName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.attendee")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "lastName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const {
          attendeeFirstName,
          attendeeLastName,
          attendeeProfession,
          attendeeEmail,
        } = row.original;

        const name = `${attendeeFirstName} ${attendeeLastName}`;

        return (
          <Stack spacing={1}>
            <HStack gap={2}>
              <Avatar
                h="2.25rem"
                w="2.25rem"
                name={name}
                // bg="#dceeff" color="#3a74a9"
              />

              <Stack gap={0}>
                <Text
                  fontSize="sm"
                  color={textPrimary}
                  fontWeight="600"
                  isTruncated
                  maxWidth="200px"
                >
                  {name}
                </Text>
                {attendeeProfession && (
                  <Text
                    fontSize="xs"
                    color={textSecondary}
                    fontWeight="500"
                    isTruncated
                    noOfLines={1}
                    whiteSpace="normal"
                    overflow="hidden"
                    w="100%"
                    maxWidth="200px"
                  >
                    {attendeeEmail}
                    {/* {attendeeProfession.name} */}
                  </Text>
                )}
              </Stack>
            </HStack>
          </Stack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("profession")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.profession")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "profession",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { attendeeProfession, attendeePhone } = row.original;

        return (
          <Stack spacing={1}>
            <Text
              fontSize="sm"
              color={textSecondary}
              fontWeight="500"
              isTruncated
              maxWidth="120px"
            >
              {attendeeProfession.name}
            </Text>

            <Text
              fontSize="0.8rem"
              color={textSecondary}
              fontWeight="500"
              isTruncated
              maxWidth="120px"
            >
              {attendeePhone}
            </Text>
          </Stack>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("clientName")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.columnCompany")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "clientName",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { attendeeCompanyName } = row.original;
        const isAttendee = row.original.attendee !== null;

        const clientFirstName =
          row.original.attendee &&
          row.original.attendee.client &&
          row.original.attendee.client.firstName
            ? row.original.attendee.client.firstName
            : null;

        const clientLastName =
          row.original.attendee &&
          row.original.attendee.client &&
          row.original.attendee.client.lastName
            ? row.original.attendee.client.lastName
            : null;

        const clientCompanyName =
          row.original.attendee &&
          row.original.attendee.client &&
          row.original.attendee.client.companyName
            ? row.original.attendee.client.companyName
            : null;

        const clientClientType =
          row.original.attendee &&
          row.original.attendee.client &&
          row.original.attendee.client.clientType
            ? row.original.attendee.client.clientType
            : null;

        const name = `${clientFirstName} ${clientLastName}`;

        // console.log("row", row.original);

        return (
          <HStack gap={2}>
            {isAttendee ? (
              <>
                <Tooltip
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  placement="top"
                  label={
                    <Text fontSize="xs" color={white} fontWeight="500">
                      {clientClientType === "individual"
                        ? name
                        : clientCompanyName}
                    </Text>
                  }
                >
                  <Flex align="center" gap="0rem">
                    <Flex
                      align="center"
                      justify="center"
                      w="1.75rem"
                      h="1.75rem"
                      borderRadius="10rem"
                      bg={backgroundLight}
                      mr={2}
                    >
                      <Icon
                        as={
                          clientClientType === "business" ? FaSuitcase : FaUser
                        }
                        boxSize={2.5}
                        color={textSecondary}
                      />
                    </Flex>
                    <Text
                      fontSize="13px"
                      color={textSecondary}
                      fontWeight="500"
                      maxW="120px"
                      isTruncated
                    >
                      {clientClientType === "individual"
                        ? name
                        : clientCompanyName}
                    </Text>
                  </Flex>
                </Tooltip>
              </>
            ) : (
              <>
                {attendeeCompanyName ? (
                  <Tooltip
                    p="0.5rem 1rem"
                    borderRadius="1rem"
                    placement="top"
                    label={
                      <Flex direction="column" align="center" gap="0rem">
                        <Text
                          fontSize="xs"
                          fontWeight="500"
                          color={white}
                          textAlign="center"
                        >
                          {attendeeCompanyName}
                        </Text>
                      </Flex>
                    }
                  >
                    <Text
                      fontSize="13px"
                      color={textSecondary}
                      fontWeight="500"
                      maxW="120px"
                      isTruncated
                    >
                      {attendeeCompanyName}
                    </Text>
                  </Tooltip>
                ) : (
                  <Text fontSize="xs" color={textSecondary} fontWeight="500">
                    -
                  </Text>
                )}
              </>
            )}
          </HStack>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("price")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("common.price")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "price",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { price, registerPrice } = row.original;

        // console.log("row", row.original);

        // console.log("price", price);

        const handleUpdatePrice = (newPrice) => {
          console.log("Updating price:", newPrice);
          updateCourseAttendeePriceHandler(row.original.attendee._id, newPrice);
        };

        return (
          <>
            <Flex align="center" minW="80px">
              {price ? (
                <>
                  <PricePopover
                    price={price}
                    defaultPrice={registerPrice}
                    onChange={handleUpdatePrice}
                  />
                </>
              ) : (
                <PricePopover
                  price={price}
                  defaultPrice={registerPrice}
                  onChange={handleUpdatePrice}
                  isBig
                />
              )}
            </Flex>
          </>
        );
      },
    },

    {
      Header: () => (
        <Box onClick={() => handleSortChange("paid")}>
          <Flex align="center" cursor="pointer">
            <Text mr={2}>{translate("attendee.payment")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "paid",
      disableSortBy: true,
      Cell: ({ row }) => {
        const { paid } = row.original;

        const attendee = row.original;

        const { _id } = attendee;

        const attendeeId = _id;

        // console.log("attendeeId", attendeeId);

        const handleUpdatePaidStatus = (newStatus) => {
          console.log("Updating paid status:", newStatus);
          console.log("newStatus", newStatus);
          updateCourseAttendeePaidStatusHandler(attendeeId, newStatus);
        };

        return (
          <Flex>
            {/* <Text fontSize="sm" fontWeight="500" color={textSecondary}>
              {paid ? 'Paid' : 'Not paid'}
            </Text> */}

            {/* <ToggleButton
              initialStatus={paid} // Pass the initial status (true or false)
              onToggle={(newStatus) => handleUpdatePaidStatus(newStatus)} // Pass the callback function to handle the toggle
            /> */}
            <Button
              size="sm"
              h="2.5rem"
              w="2.5rem"
              borderRadius="0.875rem"
              border={`0px solid ${newBorder}`}
              bg={paid ? greenVivid : buttonRed}
              onClick={() => handleUpdatePaidStatus(!paid)}
              _hover={{
                bg: shade(0.1, paid ? greenVivid : buttonRed),
              }}
            >
              <Icon
                as={paid ? FaRegCircleCheck : FaRegCircleXmark}
                color={paid ? textPrimary : buttonRedText}
                fontSize="0.875rem"
              />
            </Button>
          </Flex>
        );
      },
    },
    {
      Header: () => (
        <Box onClick={() => handleSortChange("status")} w="100%">
          <Flex align="center" cursor="pointer" justify="flex-end" w="100%">
            <Text mr={2}>{translate("common.columnStatus")}</Text>
          </Flex>
        </Box>
      ),
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }) => {
        // Access the full data object for the row
        const { status, _id, attendee, isVerified, cancelReason, cancelledAt } =
          row.original; // Directly destructuring `_id` from `row.original`

        const attendeeId = attendee ? attendee._id : null;
        const publicAttendeeId = _id;

        console.log("cancelReason", cancelReason);
        console.log("cancelledAt", cancelledAt);

        const handleUpdateStatus = (newStatus) => {
          console.log("newStatus", newStatus);
          updateCourseAttendeeStatusHandler(
            publicAttendeeId,
            attendeeId,
            newStatus
          );
        };

        return (
          <HStack gap={0} justify="flex-end">
            <Tooltip
              label={
                <Flex direction="column" align="flex-start" gap="0.5rem">
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color={white}
                    textAlign="center"
                  >
                    {translate("common.cancelledByUser")}
                  </Text>
                  <Flex w="100%" h="1px" bg={textSecondary} m="0rem 0" />

                  <TooltipEntry
                    label={translate(`status.${status}`)}
                    icon={TbUser}
                    maxW="160px"
                    text={translate("clientAccount.attendeeStatus")}
                  />
                  <TooltipEntry
                    label={dayjs(cancelledAt).format("YYYY-MM-DD, HH:mm")}
                    icon={TbUser}
                    maxW="160px"
                    text={translate("common.statusUpdatedAt")}
                  />
                  <TooltipEntry
                    label={cancelReason || "-"}
                    icon={TbUser}
                    maxW="160px"
                    text={translate("implantFailureReports.cancelReason")}
                  />
                </Flex>
              }
              p="0.5rem 1rem"
              borderRadius="1rem"
              isDisabled={cancelReason === null}
            >
              <Flex>
                <StatusButton
                  currentStatus={!isVerified ? "unverified" : status}
                  isUnclickable={!isVerified || cancelReason !== null}
                  onUpdateStatus={handleUpdateStatus}
                  options="attendees"
                  isUserCancelled={cancelReason !== null}
                />
              </Flex>
            </Tooltip>
            {attendeeId !== null ? (
              <MoreButton
                id={attendeeId}
                deleteHandler={removeAttendeeHandler}
                deleteLabel={translate("course.removeAttendee")}
              />
            ) : (
              <MoreButton
                id={publicAttendeeId}
                deleteHandler={removeAttendeeHandlerPublic}
                deleteLabel={translate("course.removeAttendee")}
              />
            )}
          </HStack>
        );
      },
    },
  ];
};
