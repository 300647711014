import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Stack,
  Heading,
  Text,
  Image,
  Button,
  Icon,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import {
  primary,
  white,
  textPrimary,
  textSecondary,
  newBorder,
  backgroundLight,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  TbCalendar,
  TbHash,
  TbDentalBroken,
  TbRadioactive,
  TbUser,
} from "react-icons/tb";
import StatusButton from "../../../../components/buttons/StatusButton";
import { FaExchangeAlt } from "react-icons/fa";
import noServices from "../../../../assets/images/empty-box.png";

import { motion } from "framer-motion";
import { shade } from "polished";
import { FaInfo } from "react-icons/fa6";

const UserReportsMini = ({ loading, error, reports }) => {
  // GENERAL
  const dispatch = useDispatch();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // REF
  const lastReportRef = useRef(null);

  const [filteredReports, setFilteredReports] = useState([]);

  //   // HANDLERS

  //   const loadMore = () => {
  //     if (page < pages) {
  //       setPage((prev) => prev + 1);
  //     }
  //   };

  // const scrollToLastReport = () => {
  //   if (lastReportRef.current) {
  //     lastReportRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const TooltipEntry = ({ label, icon, capitalize, text, maxW }) => {
    return (
      <Flex align="center">
        <Flex
          w="1.25rem"
          h="1.25rem"
          align="center"
          justify="center"
          bg={white}
          mr={2}
          borderRadius="50%"
          flexShrink="0"
        >
          <Icon as={icon} fontSize="0.875rem" color={textSecondary} />
        </Flex>
        {text && (
          <Text
            fontSize="sm"
            fontWeight="500"
            textTransform={capitalize ? "capitalize" : "none"}
            opacity={0.6}
            mr={1}
            whiteSpace="nowrap"
          >
            {text}:
          </Text>
        )}

        <Text
          fontSize="sm"
          fontWeight="500"
          textTransform={capitalize ? "capitalize" : "none"}
          maxW={maxW}
          isTruncated={maxW ? true : false}
        >
          {label}
        </Text>
      </Flex>
    );
  };

  // USE EFFECT
  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: error,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [error]);

  useEffect(() => {
    // i need last 3 reports
    if (reports && reports.length > 0) {
      setFilteredReports(reports.slice(0, 3));
    } else {
      setFilteredReports([]);
    }
  }, [reports]);

  // LOGS
  //   console.log("userReports", userReports);
  //   console.log("pages", pages);
  //   console.log("totalReports", totalReports);

  return (
    <>
      <Flex
        w={["100%", "100%", "initial"]}
        overflow={["hidden", "hidden", "initial"]}
        direction="column"
        p="0px 0 0"
      >
        <Flex direction="column" w="100%">
          <Stack gap="1rem" w="100%" m="0rem 0">
            {reports &&
              reports.length > 0 &&
              filteredReports.map((report, index) => (
                <Flex
                  key={report._id}
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <Flex
                    bg={white}
                    w="100%"
                    p={["1rem 1.5rem", "1rem 1.5rem", "1rem 2rem"]}
                    h="90px"
                    borderRadius="1rem"
                    align="center"
                    justify="space-between"
                    ref={
                      report._id === reports.length - 1 ? lastReportRef : null
                    }
                    transition="all 0.2s"
                    onClick={() => {
                      navigate(`/account/forms/${report._id}`);
                    }}
                    _hover={{
                      cursor: "pointer",
                      shadow: "sm",
                      transform: "scale(1.02)",
                    }}
                  >
                    <Flex align="center">
                      <Stack
                        gap="0.25rem"
                        mr={["0rem", "0rem", "2rem"]}
                        w="140px"
                      >
                        <Heading
                          fontSize="sm"
                          fontWeight="600"
                          textTransform="capitalize"
                        >
                          {dayjs(report.createdAt).format("MMMM D")}
                        </Heading>
                        <Text
                          color={textSecondary}
                          fontSize="xs"
                          fontWeight="500"
                          maxW={["100px", "100px", "initial"]}
                          isTruncated
                        >
                          {report.trackingNumber}
                        </Text>
                      </Stack>

                      <Stack
                        gap="0rem"
                        mr="0rem"
                        minW="170px"
                        maxW="170px"
                        align="center"
                        justify="center"
                        display={["none", "none", "flex"]}
                      >
                        <Text
                          fontSize="sm"
                          fontWeight="600"
                          color={textPrimary}
                        >
                          {t(`implants.${report.fixture}`)}
                          {report.core === null ? null : (
                            <>
                              {report.core === "regular" ||
                              report.core === "special" ||
                              report.core === "deep" ? (
                                <Text
                                  as="span"
                                  fontSize="sm"
                                  color={textPrimary}
                                  fontWeight="500"
                                >
                                  {" "}
                                  - ({t(`coreSimple.${report.core}`)})
                                </Text>
                              ) : (
                                <Text
                                  as="span"
                                  fontSize="sm"
                                  color={textPrimary}
                                  fontWeight="500"
                                >
                                  - (C{report.core})
                                </Text>
                              )}
                            </>
                          )}
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {" "}
                          Ø{report.diameter}mm/L{report.length}{" "}
                        </Text>
                      </Stack>

                      <Flex
                        align="center"
                        gap="1rem"
                        px="1rem"
                        justify="center"
                        textAlign="center"
                        display={["none", "none", "flex"]}
                      >
                        <Icon
                          as={FaExchangeAlt}
                          fontSize="0.875rem"
                          color={textSecondary}
                          mr={2}
                        />
                      </Flex>
                      <Stack
                        gap="0rem"
                        mr="0rem"
                        minW="170px"
                        maxW="170px"
                        align="center"
                        justify="center"
                        display={["none", "none", "flex"]}
                      >
                        <Text
                          fontSize="sm"
                          fontWeight="600"
                          color={textPrimary}
                        >
                          {t(`implants.${report.newFixture}`)}
                          {report.newCore === null ? null : (
                            <>
                              {report.newCore === "regular" ||
                              report.newCore === "special" ||
                              report.newCore === "deep" ? (
                                <Text
                                  as="span"
                                  fontSize="sm"
                                  color={textPrimary}
                                  fontWeight="500"
                                >
                                  {" "}
                                  - ({t(`coreSimple.${report.newCore}`)})
                                </Text>
                              ) : (
                                <Text
                                  as="span"
                                  fontSize="sm"
                                  color={textPrimary}
                                  fontWeight="600"
                                >
                                  - (C{report.newCore})
                                </Text>
                              )}
                            </>
                          )}
                        </Text>
                        <Text
                          fontSize="sm"
                          fontWeight="500"
                          color={textSecondary}
                        >
                          {" "}
                          Ø{report.newDiameter}mm/L{report.newLength}{" "}
                        </Text>
                      </Stack>
                    </Flex>

                    <Flex align="center" gap="1rem">
                      <Tooltip
                        p="1rem 1rem"
                        label={
                          <Flex direction="column" gap="0.5rem">
                            <TooltipEntry
                              label={dayjs(report.createdAt).format(
                                "MMMM D, YYYY"
                              )}
                              icon={TbCalendar}
                              capitalize
                            />
                            <TooltipEntry
                              label={report.trackingNumber}
                              icon={TbHash}
                            />
                            <Flex
                              w="100%"
                              h="1px"
                              bg={textSecondary}
                              m="0.25rem 0"
                            />
                            {report.reason &&
                            report.reasonText &&
                            report.reason === "Other" ? (
                              <TooltipEntry
                                label={report.reasonText}
                                icon={TbDentalBroken}
                                maxW="160px"
                                text={t("implantForm.reason")}
                              />
                            ) : (
                              <TooltipEntry
                                label={t(`implantForm.${report.reason}`)}
                                icon={TbDentalBroken}
                                maxW="160px"
                                text={t("implantForm.reason")}
                              />
                            )}

                            <TooltipEntry
                              label={report.tooth}
                              icon={TbDentalBroken}
                              text={t("implantForm.teeth")}
                            />

                            <TooltipEntry
                              label={
                                report.surgeryDate === null
                                  ? "-"
                                  : dayjs(report.surgeryDate).format(
                                      "YYYY-MM-DD"
                                    )
                              }
                              icon={TbDentalBroken}
                              text={t("implantForm.surgeryDate")}
                            />

                            <TooltipEntry
                              label={
                                report.failureDate !== null
                                  ? dayjs(report.failureDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  : "-"
                              }
                              icon={TbDentalBroken}
                              text={t("implantForm.failureDateAlt")}
                            />

                            <TooltipEntry
                              label={
                                report.beforeXrayImage
                                  ? t("implantForm.exists")
                                  : t("implantForm.noExists")
                              }
                              icon={TbRadioactive}
                              text={t("implantForm.beforeImage")}
                            />
                            <TooltipEntry
                              label={
                                report.afterXrayImage
                                  ? t("implantForm.exists")
                                  : t("implantForm.noExists")
                              }
                              icon={TbRadioactive}
                              text={t("implantForm.afterImage")}
                            />
                            <TooltipEntry
                              label={
                                report.surgeonFirstName &&
                                report.surgeonLastName &&
                                report.surgeonFirstName +
                                  " " +
                                  report.surgeonLastName
                              }
                              icon={TbUser}
                              text={t("implantForm.surgeon")}
                            />
                            <TooltipEntry
                              label={
                                report.documentNumber
                                  ? report.documentNumber
                                  : "-"
                              }
                              icon={TbHash}
                              maxW="250px"
                              text={t("form.documentNumber")}
                            />
                          </Flex>
                        }
                        borderRadius="1rem"
                      >
                        <Button
                          h="2.5rem"
                          w="2.5rem"
                          bg={backgroundLight}
                          border={`1px solid ${newBorder}`}
                          borderRadius="0.875rem"
                          display={["none", "none", "flex"]}
                        >
                          <Icon
                            as={FaInfo}
                            fontSize="0.75rem"
                            color={textSecondary}
                          />
                        </Button>
                      </Tooltip>
                      <StatusButton
                        isUnclickable
                        currentStatus={report && report.status && report.status}
                        options="report"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              ))}
          </Stack>
          {/* {page < pages && (
                <Button onClick={loadMore} isLoading={loadingReports}>
                  {t("common.loadMore")}
                </Button>
              )} */}
        </Flex>

        {reports && reports.length === 0 && (
          <Flex
            w="100%"
            align="center"
            justify="center"
            p="0rem 0"
            bg={white}
            borderRadius="1rem"
            direction="column"
            border={`0px solid ${newBorder}`}
          >
            <Flex justify="center" align="center" direction="column" p="3rem 0">
              <Image h="4rem" w="auto" m="0rem 0 0" src={noServices} />
              <Text
                fontSize="sm"
                color={textSecondary}
                fontWeight="500"
                m="1rem auto 1rem"
                maxW="300px"
                textAlign="center"
              >
                {t("clientAccount.noReportsText")}
              </Text>
              <Tooltip
                p="0.5rem 1rem"
                borderRadius="1rem"
                placement="top"
                label={t("landingMegagen.comingSoonLink")}
              >
                <Button
                  bg={primary}
                  color={white}
                  size="lg"
                  fontSize="sm"
                  borderRadius="10rem"
                  onClick={() => {
                    navigate("/implant-fail-form");
                  }}
                  _hover={{
                    bg: shade(0.3, primary),
                  }}
                  isDisabled
                >
                  {t("clientAccount.noReportsButton")}
                </Button>
              </Tooltip>
            </Flex>
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default UserReportsMini;
