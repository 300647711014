import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { mobile } from "../../../utils/breakpoints";
import { useInView } from "react-intersection-observer";
import {
  newBorder,
  white,
  textPrimary,
  textSecondary,
  backgroundLight,
  primary,
} from "../../../utils/colors";
import {
  Flex,
  Heading,
  Text,
  Grid,
  GridItem,
  Image,
  Button,
} from "@chakra-ui/react";
import { useMediaQuery } from "react-responsive";
import { divTransition } from "../../../assets/animations/divTransition";
import { motion, useAnimation } from "framer-motion";
import { useTranslation } from "react-i18next";
import mggIcon from "../../../assets/icons/mgg-icon.svg";
import { shade } from "polished";

export const About = ({ userInfo }) => {
  let { t } = useTranslation("");
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: mobile });

  const control = useAnimation();
  const [ref, inView] = useInView();

  const blocks = [
    {
      title: t("landingMegagen.aboutBlock2Title"),
      text: t("landingMegagen.aboutBlock2Text"),
    },
    {
      title: t("landingMegagen.aboutBlock1Title"),
      text: t("landingMegagen.aboutBlock1Text"),
    },
    {
      title: t("landingMegagen.aboutBlock3Title"),
      text: t("landingMegagen.aboutBlock3Text"),
    },
  ];

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Flex
      as={motion.div}
      ref={ref}
      variants={divTransition}
      initial="hidden"
      animate={control}
      w="100%"
      m={["0rem 0 0", "0rem 0 0", "8rem 0 0"]}
      borderTop={[`1px solid ${newBorder}`, `1px solid ${newBorder}`, "none"]}
      p={["calc(80px + 4rem) 1.5rem 0", "2rem 0 0", "0 0 0"]}
    >
      <Flex
        w="100%"
        direction="column"
        maxW={["100%", "100%", "1200px"]}
        m="0 auto"
        p={["0rem 0", "0rem 0", "8rem 0"]}
        justify="center"
      >
        <Heading
          fontSize={["2rem", "2rem", "3rem"]}
          fontWeight="700"
          color={textPrimary}
        >
          {t("landingMegagen.aboutTitle")} <br />
          <Text as="span" fontWeight="600">
            {t("landingMegagen.aboutTitle2")}
          </Text>
        </Heading>

        <Flex w="100%" align="center" justify="center" direction="column">
          <Grid
            templateColumns={[
              "repeat(1, 1fr)",
              "repeat(1, 1fr)",
              "repeat(3, 1fr)",
            ]}
            gap={6}
            w="100%"
            m={["2.5rem auto 0", "2.5rem auto 0", "3rem auto 0"]}
          >
            {blocks.map((block, index) => (
              <GridItem key={index}>
                <Flex
                  bg={backgroundLight}
                  direction="column"
                  borderRadius="1rem"
                  p="2rem"
                  h="100%"
                >
                  <Flex align="center">
                    <Flex
                      align="center"
                      justify="center"
                      w="48px"
                      h="48px"
                      bg={white}
                      borderRadius="50%"
                      mr="1rem"
                    >
                      <Image src={mggIcon} h="2.5rem" />
                    </Flex>

                    <Heading
                      fontSize="1.25rem"
                      fontWeight="600"
                      color={textPrimary}
                    >
                      {block.title}
                    </Heading>
                  </Flex>

                  <Text
                    m="1.25rem 0 0"
                    fontSize="1rem"
                    fontWeight="500"
                    color={textSecondary}
                    lineHeight="1.5"
                  >
                    {block.text}
                  </Text>

                  {isMobile && (
                    <Button
                      m="3rem 0 0"
                      size="lg"
                      fontSize="md"
                      bg={primary}
                      color={white}
                      borderRadius="10rem"
                      px="3rem"
                      _hover={{ bg: shade(0.3, primary) }}
                      onClick={
                        userInfo
                          ? () => navigate("/account")
                          : () => navigate("/register")
                      }
                    >
                      {userInfo
                        ? t("landingMegagen.menuMyPortal")
                        : t("landingMegagen.registerButton")}
                    </Button>
                  )}
                </Flex>
              </GridItem>
            ))}
          </Grid>
          {!isMobile && (
            <Button
              m="3rem 0 0"
              size="lg"
              fontSize="md"
              bg={primary}
              color={white}
              borderRadius="10rem"
              px="3rem"
              _hover={{ bg: shade(0.3, primary) }}
              onClick={
                userInfo
                  ? userInfo.role === "employee"
                    ? () => navigate("/admin/dashboard")
                    : () => navigate("/account")
                  : () => navigate("/register")
              }
            >
              {userInfo
                ? userInfo.role === "employee"
                  ? t("landingMegagen.menuAdmin")
                  : t("landingMegagen.menuMyPortal")
                : t("landingMegagen.registerButton")}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default About;
