import React, { useEffect } from "react";
import styled from "styled-components";
import { ChakraProvider, Box, Text } from "@chakra-ui/react";
import theme from "./theme";
import {
  BrowserRouter as Router,
  useLocation,
  matchPath,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { backgroundLight, primary, white } from "./utils/colors";
import { useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { GoogleApiProvider } from "./contexts/GoogleApiContext";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Header } from "./components/layout/Header";
import Sidebar from "./components/shared/Sidebar";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs"; // Import dayjs here
import getMenuItems from "./components/menu/MenuItems";
import PublicRoutes from "./routes/PublicRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import AdminRoutes from "./routes/AdminRoutes";
// import Hotjar from "@hotjar/browser";
import { Flex } from "@chakra-ui/react";
import ReactGA from "react-ga4";
import useHotjar from "react-use-hotjar";

const AuthenticatedApp = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const isAdmin = userInfo && userInfo.isAdmin;

  const isEmployee = userInfo && userInfo.role === "employee";

  // Use the renamed import here
  const menuItems = getMenuItems(isAdmin);

  const location = useLocation();

  // const excludedPaths = [
  //   "/",
  //   "/implant-fail-form",
  //   "/events",
  //   "/events/:id",
  //   "/register",
  //   "/login",
  //   "/account",
  // ];

  // Use matchPath to match dynamic paths
  // const isExcludedPath = excludedPaths.some((path) =>
  //   matchPath({ path, exact: false }, location.pathname)
  // );

  const includedPaths = ["/admin"];

  // Updated to match any subpaths under /admin
  const isIncludedPath = includedPaths.some((path) =>
    matchPath({ path: `${path}/*`, end: false }, location.pathname)
  );

  const showSidebarAndWrapper = userInfo && isIncludedPath;

  return (
    <>
      {isEmployee ? (
        <Flex>
          {showSidebarAndWrapper ? (
            <Wrapper>
              <Sidebar.Wrapper>
                <Sidebar.Container>
                  <Sidebar.Routing menuItems={menuItems} />
                </Sidebar.Container>
              </Sidebar.Wrapper>
              <InnerWrapper>
                <Box
                  display={{ base: "flex", md: "none" }}
                  bg={primary}
                  h="100vh"
                  w="100%"
                  overflow="hidden"
                  alignItems="center"
                  justifyContent="center"
                  p={8}
                  textAlign="center"
                >
                  <Text
                    color={white}
                    fontWeight="500"
                    fontSize="1.5rem"
                    maxWidth="80%"
                  >
                    Mobile version coming soon
                  </Text>
                </Box>
                <Main>
                  <Header />
                  <AnimatePresence mode="wait">
                    <AdminRoutes />
                  </AnimatePresence>
                </Main>
              </InnerWrapper>
            </Wrapper>
          ) : (
            <Main>
              <Header />
              <AnimatePresence mode="wait">
                <AdminRoutes />
              </AnimatePresence>
            </Main>
          )}
        </Flex>
      ) : (
        <PrivateRoutes userInfo={userInfo} />
      )}
    </>
  );
};

const App = () => {
  const { t, i18n } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const { initHotjar } = useHotjar();

  useEffect(() => {
    // Retrieve the selected language from localStorage
    const storedLanguage = localStorage.getItem("language");
    const initialLanguage = storedLanguage || "lt"; // Default to 'en' if no language is stored

    // Initialize i18n with the stored or default language
    i18n.changeLanguage(initialLanguage);
    dayjs.locale(initialLanguage); // Initialize dayjs with the selected language

    // eslint-disable-next-line
  }, []);

  const myCustomLogger = console.info;

  useEffect(() => {
    initHotjar("5021144", 6, false, myCustomLogger);
    // eslint-disable-next-line
  }, [initHotjar]);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
    ReactGA.send("pageview");
  }, []);

  return (
    <>
      <ChakraProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <GoogleApiProvider
            googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          >
            <Router>
              {userInfo ? <AuthenticatedApp /> : <PublicRoutes />}
            </Router>
          </GoogleApiProvider>
        </LocalizationProvider>
      </ChakraProvider>
    </>
  );
};
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  /* background: #eaf3ff; */
  background: #f8f8f8;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 280px);
  position: relative;
  left: 280px;
  @media (max-width: 768px) {
    left: 0;
    width: 100%;
  }
`;

const Main = styled.div`
  padding: 0px 0 0;
  box-sizing: border-box;
  min-height: 100vh;
  /* background: ${backgroundLight}; */
  width: 100%;
`;

export default App;
