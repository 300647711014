import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Flex,
  Text,
  Stack,
  Heading,
  useToast,
  FormControl,
  HStack,
  Button,
  Switch,
  Spinner,
} from "@chakra-ui/react";
import {
  white,
  textSecondary,
  textPrimary,
  newBorder,
  backgroundLight,
  primary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import ImageUpload from "../../../../components/input/ImageUpload";
import FormControlPhone from "../../../../components/input/FormControlPhone";
import FormControlProfessionNew from "../../../../components/input/FormControlProfessionNew";
import { Helmet } from "react-helmet";
import Header from "../../../landing/layout/Header";
import AccountTabs from "../partials/AccountTabs";
import Faq from "../../../general/partials/Faq";
import Footer from "../../../landing/layout/Footer";
import {
  getUserClientDetails,
  updateUserClientDetails,
} from "../../../../actions/userActions";
import { motion } from "framer-motion";
import { shade } from "polished";
import dayjs from "dayjs";
import { USER_UPDATE_DETAILS_RESET } from "../../../../constants/userConstants";
import FormControlAddressFull from "../../../../components/input/FormControlAddressFull";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import { GoogleApiContext } from "../../../../contexts/GoogleApiContext";
import { useMediaQuery } from "react-responsive";

const UserAccountSettings = ({ userInfo }) => {
  // GENERAL
  const { t } = useTranslation();
  const navigate = useNavigate();

  // GOOGLE API
  const { isApiLoaded } = useContext(GoogleApiContext);

  // CHECKOUT STATE
  //   const { checkoutState } = useCheckout();

  // TOAST
  const toast = useToast();

  // STATE
  const [manualAddressVisible, setManualAddressVisible] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [image, setImage] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [profession, setProfession] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [notificationSms, setNotificationSms] = useState(false);
  const [notificationEmail, setNotificationEmail] = useState(false);

  const [address, setAddress] = useState({
    street: "",
    city: "",
    country: "",
    zip: "",
    state: "",
    display: "",
  });

  const [companyName, setCompanyName] = useState("");
  const [companyCode, setCompanyCode] = useState("");

  const [hasChanged, setHasChanged] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(null);

  const [displayAddress, setDisplayAddress] = useState("");

  // REDUX
  const dispatch = useDispatch();

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const userGetClientDetails = useSelector(
    (state) => state.userGetClientDetails
  );

  const {
    loading: loadingClientDetails,
    error: errorClientDetails,
    client,
  } = userGetClientDetails;

  const userUpdateDetails = useSelector((state) => state.userUpdateDetails);

  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedAt: updatedAtUpdate,
  } = userUpdateDetails;

  // TABS

  // Adjusting tabs based on the client type
  let tabs = [
    {
      title: t("landingMegagen.userAccountTab0"),
      disabled: false,
      path: "/account",
    },
    {
      title: t("landingMegagen.userAccountTab1"),
      disabled: true,
      path: "/account/forms",
    },
    {
      title: t("landingMegagen.userAccountTab2"),
      disabled: false,
      path: "/account/events",
    },
    {
      title: t("landingMegagen.userAccountTab3"),
      disabled: false,
      path: "/account/employees",
    },
  ];

  if (userInfo && userInfo.clientType === "individual") {
    tabs = tabs.filter((tab, index) => index !== 3); // Remove the fourth tab if clientType is 'individual'
  }

  // HANDLERS

  const handleAddressSelect = (selectedAddress) => {
    const { street, city, zip, country, state } = selectedAddress;

    // Set each part of the address to state
    setAddress({
      street: street || "",
      city: city || "",
      zip: zip || "",
      state: state || "",
      country: country || "",
      display: "",
    });

    // Build the display string from parts that are not empty
    const parts = [street, city, state, zip, country].filter(Boolean);
    const displayString = parts.join(", ");

    // Set the display address
    setDisplayAddress(displayString);
    setHasChanged(true);
  };

  const toggleManualAddressVisible = () => {
    setManualAddressVisible(!manualAddressVisible);
  };

  const handleImageChange = (image) => {
    setImage(image);
    setHasChanged(true);
  };

  const handleProfessionChange = (selectedOption) => {
    setProfession(selectedOption.value);
    setHasChanged(true);
  };

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
    setHasChanged(true);
  };

  const handleCompanyCodeChange = (e) => {
    setCompanyCode(e.target.value);
    setHasChanged(true);
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setHasChanged(true);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setHasChanged(true);
  };

  const handleStreetChange = (e) => {
    setAddress({
      ...address,
      street: e.target.value,
    });
    setHasChanged(true);
  };

  const handleCityChange = (e) => {
    setAddress({
      ...address,
      city: e.target.value,
    });
    setHasChanged(true);
  };

  const handleZipChange = (e) => {
    setAddress({
      ...address,
      zip: e.target.value,
    });
    setHasChanged(true);
  };

  const handleCountryChange = (selectedOption) => {
    setAddress(
      Object.assign({}, address, {
        country: selectedOption.value,
      })
    );
    setHasChanged(true);
  };

  const handleStateChange = (e) => {
    setAddress({
      ...address,
      state: e.target.value,
    });
    setHasChanged(true);
  };

  const handlePhoneChange = (newPhone) => {
    setPhone(newPhone);
    setHasChanged(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setHasChanged(true);
  };

  const handleSubmit = () => {
    if (!hasChanged) {
      toast({
        title: t("No changes detected."),
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let payload = {};

    if (client.clientType === "individual") {
      payload = {
        image,
        firstName,
        lastName,
        phone,
        profession,
        notificationEmail,
        notificationSms,
        address,
        companyName,
        companyCode,
      };
    } else {
      payload = {
        image,
        companyName,
        companyCode,
        phone,
        notificationEmail,
        notificationSms,
        address,
      };
    }

    console.log("Payload to send:", payload);
    dispatch(updateUserClientDetails(payload));
  };

  // USE EFFECT
  useEffect(() => {
    //scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (userInfo) {
      console.log("getUserClientDetails");
      dispatch(getUserClientDetails(userInfo._id));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (client) {
      setImage(client.image);
      setFirstName(client.firstName);
      setLastName(client.lastName);
      setEmail(client.email);
      setPhone(client.phone);
      setProfession(client.profession && client.profession._id);
      setNotificationEmail(client.notificationEmail);
      setNotificationSms(client.notificationSms);
      setUpdatedAt(client.updatedAt);

      setAddress({
        street:
          (client &&
            client.address &&
            client.address.length > 0 &&
            client.address[0].street) ||
          "",
        city:
          (client &&
            client.address &&
            client.address.length > 0 &&
            client.address[0].city) ||
          "",
        country:
          (client &&
            client.address &&
            client.address.length > 0 &&
            client.address[0].country) ||
          "",
        zip:
          (client &&
            client.address &&
            client.address.length > 0 &&
            client.address[0].zip) ||
          "",
        state:
          (client &&
            client.address &&
            client.address.length > 0 &&
            client.address[0].state) ||
          "",
      });

      setCompanyName(client.companyName);
      setCompanyCode(client.companyCode);
    } else {
      // Reset all fields if no client data is present
      setImage(null);
      setFirstName("");
      setLastName("");
      setPhone("");
      setEmail("");
      setProfession("");
      setNotificationEmail(false);
      setNotificationSms(false);
      setUpdatedAt(null);
      setCompanyName("");
      setCompanyCode("");
      setAddress({
        street: "",
        city: "",
        country: "",
        zip: "",
        state: "",
        display: "",
      });

      // setDisplayAddress("");
    }
  }, [client]);

  useEffect(() => {
    if (client && client.address) {
      const { street, city, zip, country, state } =
        client &&
        client.address &&
        client.address.length > 0 &&
        client.address[0]; // Assuming address[0] exists and is the correct format
      const parts = [street, city, state, zip, country].filter(Boolean);
      const fullAddress = parts.join(", ");

      setAddress({
        street: street || "",
        city: city || "",
        zip: zip || "",
        state: state || "",
        country: country || "",
        display: fullAddress,
      });

      setDisplayAddress(fullAddress);
    }
  }, [client]);

  useEffect(() => {
    if (successUpdate) {
      toast({
        title: t("toasts.userClientUpdateSuccess"),
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // setUpdatedAt(updatedAtUpdate);
      setHasChanged(false);
      dispatch({ type: USER_UPDATE_DETAILS_RESET });
      if (userUpdateDetails.data) {
        setUpdatedAt(userUpdateDetails.data.updatedAt);
      }
    } else if (errorUpdate) {
      toast({
        title: t("toasts.userClientUpdateError"),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [successUpdate, errorUpdate]);

  useEffect(() => {
    if (updatedAtUpdate) {
      console.log("YRA updatedAtUpdate", updatedAtUpdate);
      setUpdatedAt(updatedAtUpdate);
    }
  }, [updatedAtUpdate, setUpdatedAt, updatedAt]);

  // LOGS

  // console.log("client", client);
  // console.log("address", address);

  // RENDER
  return (
    <>
      <Helmet>
        <title>{t("pageTitle.userAccountSettings")}</title>
        <meta
          name="description"
          content={t("pageDescription.userAccountSettings")}
        />
      </Helmet>

      <Header />

      <Flex
        w="100%"
        direction="column"
        p={["80px 0 0", "80px 0 0", "100px 0 0"]}
      >
        <Flex
          w="100%"
          maxW={["100%", "100%", "1200px"]}
          p={["1.5rem 0rem 0", "0rem 1.5rem 0", "initial"]}
          direction="column"
          m={["0rem auto 0", "0rem auto 0", "4rem auto 0"]}
        >
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            fontWeight="700"
            color="textPrimary"
            m={["0 0 1.5remrem", "0 0 1.5rem", "0 0 1.5rem"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
          >
            {t("clientAccount.mySettings")}
          </Heading>
        </Flex>

        {!isMobile && <AccountTabs tabs={tabs} noCurrentTab />}

        <Flex
          w="100%"
          justify="center"
          gap="2rem"
          p="3rem 0 6rem"
          bg={backgroundLight}
        >
          <Flex
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            p={["0 1.5rem", "0 1.5rem", "initial"]}
            direction="column"
            m="0rem auto 0"
          >
            <Flex w="100%" direction="column" p="0rem 0 0">
              {!isMobile ? (
                <Flex
                  w="100%"
                  align="center"
                  justify="space-between"
                  minH="3rem"
                >
                  <Heading
                    fontSize="1.125rem"
                    fontWeight="600"
                    color={textPrimary}
                  >
                    {t("clientAccount.mySettings")}{" "}
                  </Heading>
                  <Flex align="center">
                    <Text
                      fontSize="sm"
                      color={textSecondary}
                      fontWeight="500"
                      mr="1.5rem"
                    >
                      {t("clientAccount.lastUpdated")}:
                      <Text
                        as="span"
                        fontWeight="500"
                        color={textPrimary}
                        ml="0.5rem"
                      >
                        {updatedAt
                          ? dayjs(updatedAt).format("YYYY-MM-DD / HH:mm:ss")
                          : "Not updated yet"}
                      </Text>
                    </Text>

                    <Button
                      onClick={handleSubmit}
                      isLoading={loadingUpdate}
                      size="lg"
                      fontSize="md"
                      fontWeight="600"
                      borderRadius="10rem"
                      bg={primary}
                      color={white}
                      isDisabled={!hasChanged}
                      _hover={{
                        bg: shade(0.3, primary),
                      }}
                    >
                      {t("button.save")}
                    </Button>
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  w="100%"
                  align="center"
                  justify="space-between"
                  minH="3rem"
                  direction="column"
                  position="fixed"
                  bottom="1rem"
                  p="0 4rem"
                  zIndex="100"
                  left="0"
                >
                  <Flex align="center" direction="column" w="100%">
                    <Button
                      onClick={handleSubmit}
                      isLoading={loadingUpdate}
                      size="lg"
                      w="100%"
                      fontSize="md"
                      fontWeight="600"
                      borderRadius="10rem"
                      bg={primary}
                      color={white}
                      isDisabled={!hasChanged}
                      _hover={{
                        bg: shade(0.3, primary),
                      }}
                    >
                      {t("button.save")}
                    </Button>
                  </Flex>
                </Flex>
              )}

              {!loadingClientDetails ? (
                <>
                  <Flex
                    w="100%"
                    direction="column"
                    bg={white}
                    borderRadius="2rem"
                    m="1rem 0 0"
                  >
                    <Flex
                      as={motion.div}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      w="100%"
                      direction="column"
                      p={["1.5rem", "1.5rem", "2.5rem"]}
                    >
                      <Flex
                        w="100%"
                        justify="space-between"
                        gap={["1rem", "1rem", "2rem"]}
                        direction={["column", "column", "row"]}
                      >
                        <Stack
                          gap={1}
                          w={["100%", "100%", "300px"]}
                          minW={["100%", "100%", "300px"]}
                          maxW={["100%", "100%", "300px"]}
                        >
                          <Heading fontSize="1.125rem" fontWeight="600">
                            {t("clientAccount.profileImage")}
                          </Heading>
                          <Text
                            color={textSecondary}
                            fontSize={["sm", "sm", "xs"]}
                            maxW={["100%", "100%", "300px"]}
                            fontWeight="500"
                          >
                            {t("clientAccount.profileImageText")}
                          </Text>
                        </Stack>
                        <Flex
                          w="100%"
                          direction="column"
                          bg={white}
                          borderRadius="1rem"
                          p="0rem"
                        >
                          <FormControl mb={6}>
                            {/* <FormLabel fontSize="sm">{t("course.courseImage")}</FormLabel> */}
                            <ImageUpload
                              image={image}
                              setImage={handleImageChange}
                            />
                          </FormControl>
                          {/* <FormControlEmployeeNew
            label={t("form.currentCompanyAdmin")}
            value={adminUser}
            placeholder={t("placeholder.currentCompanyAdmin")}
            onChange={handleEmployeeChangeSingle}
            isMulti={false}
            noBottom
            isSearchable={false}
          /> */}
                        </Flex>
                      </Flex>

                      <Flex
                        w="100%"
                        justify="space-between"
                        gap="2rem"
                        p="2rem 0"
                        borderTop={`1px solid ${newBorder}`}
                        direction={["column", "column", "row"]}
                      >
                        <Stack
                          gap={1}
                          w={["100%", "100%", "300px"]}
                          minW={["100%", "100%", "300px"]}
                          maxW={["100%", "100%", "300px"]}
                        >
                          <Heading fontSize="1.125rem" fontWeight="600">
                            {client.clientType === "individual"
                              ? t("clientAccount.clientInformation")
                              : t("clientAccount.companyInformation")}
                          </Heading>
                          <Text
                            color={textSecondary}
                            fontSize="xs"
                            maxW="300px"
                            fontWeight="500"
                          >
                            {client.clientType === "individual"
                              ? t("clientAccount.clientInformationText")
                              : t("clientAccount.companyInformationText")}
                          </Text>
                        </Stack>
                        <Flex
                          w="100%"
                          direction="column"
                          bg={white}
                          borderRadius="1rem"
                          p="0rem 0"
                        >
                          {client.clientType === "individual" ? (
                            <Flex
                              gap="1rem"
                              direction={["column", "column", "row"]}
                            >
                              <FormControlDefault
                                type="text"
                                label={t("form.firstName")}
                                id="firstName"
                                value={firstName}
                                onChange={handleFirstNameChange}
                                placeholder={t("placeholder.firstName")}
                                w="100%"
                              />

                              <FormControlDefault
                                type="text"
                                label={t("form.lastName")}
                                id="lastName"
                                value={lastName}
                                onChange={handleLastNameChange}
                                placeholder={t("placeholder.lastName")}
                                w="100%"
                              />
                            </Flex>
                          ) : (
                            <Flex
                              gap="1rem"
                              direction={["column", "column", "row"]}
                            >
                              <FormControlDefault
                                type="text"
                                label={t("form.companyName")}
                                id="companyName"
                                value={companyName}
                                // onChange={(e) => {
                                //   setCompanyName(e.target.value);
                                //   // setHasChanged(true);
                                // }}
                                onChange={handleCompanyNameChange}
                                placeholder={t("placeholder.companyName")}
                                w="100%"
                              />

                              <FormControlDefault
                                type="text"
                                label={t("form.companyCode")}
                                id="companyCode"
                                value={companyCode}
                                onChange={handleCompanyCodeChange}
                                placeholder={t("placeholder.companyCode")}
                                w="100%"
                              />
                            </Flex>
                          )}
                          <Flex
                            gap="1rem"
                            direction={["column", "column", "row"]}
                            m={["1rem 0 0", "1rem 0 0", "0rem 0 0 0"]}
                          >
                            <FormControlDefault
                              type="email"
                              label={t("form.email")}
                              value={email}
                              placeholder={t("placeholder.email")}
                              onChange={handleEmailChange}
                              // onBlur={() => setEmailTouched(true)}
                              // isTouched={emailTouched}
                              // isError={!isEmailValid}
                              w={["100%", "100%", "50%"]}
                              errorMessage={t("formError.emailInvalid")}
                              isDisabled
                            />
                            {client && (
                              <FormControlPhone
                                label={t("form.phone")}
                                value={phone}
                                onChange={(newPhone) => {
                                  setPhone(newPhone);
                                  setHasChanged(true);
                                }}
                                errorMessage={t("formError.phoneRequired")}
                                w={["100%", "100%", "50%"]}
                                defaultCountry="lt"
                              />
                            )}
                          </Flex>

                          {!manualAddressVisible && (
                            <Flex direction="column" w="100%" p="0rem">
                              {isApiLoaded && (
                                <FormControlAddressFull
                                  label={t("form.workAddress")}
                                  placeholder={t("placeholder.googleAddress")}
                                  onAddressSelect={handleAddressSelect}
                                  currentValue={displayAddress}
                                  w="100%"
                                  isApiLoaded={isApiLoaded}
                                />
                              )}
                              <Flex
                                align="center"
                                justify="center"
                                gap="1rem"
                                p="0.5rem 0 0"
                              >
                                <Flex
                                  position="relative"
                                  m="0rem 0 0"
                                  justify="flex-end"
                                  w="100%"
                                >
                                  <Button
                                    bg="rgba(255,255,255,0.3)"
                                    onClick={toggleManualAddressVisible}
                                    color={primary}
                                    variant="ghost"
                                    fontSize="xs"
                                    size="xs"
                                    fontWeight="500"
                                    p="0.25rem 0.75rem"
                                    borderRadius="10rem"
                                    opacity="0.8"
                                    transition="all 0.3s"
                                    _hover={{
                                      // bg: backgroundLight,
                                      opacity: "1",
                                    }}
                                  >
                                    {t("onboarding.enterAddressManual")}
                                  </Button>
                                </Flex>
                              </Flex>
                            </Flex>
                          )}

                          {manualAddressVisible && (
                            <>
                              <Flex
                                direction="column"
                                align="center"
                                justify="center"
                                p="0rem"
                              >
                                <Flex
                                  gap="1rem"
                                  direction={["column", "column", "row"]}
                                  w={["100%", "100%", "initial"]}
                                >
                                  <FormControlDefault
                                    type="text"
                                    label={t("form.street")}
                                    value={address.street}
                                    placeholder={t("placeholder.street")}
                                    onChange={handleStreetChange}
                                    w={["100%", "100%", "50%"]}
                                  />

                                  <FormControlDefault
                                    type="text"
                                    label={t("form.zip")}
                                    value={address.zip}
                                    placeholder={t("placeholder.zip")}
                                    onChange={handleZipChange}
                                    w={["100%", "100%", "50%"]}
                                  />
                                </Flex>

                                <Flex
                                  gap="1rem"
                                  direction={["column", "column", "row"]}
                                  w={["100%", "100%", "initial"]}
                                  m={["1rem 0 0", "1rem 0 0", "initial"]}
                                >
                                  <FormControlDefault
                                    type="text"
                                    label={t("form.city")}
                                    value={address.city}
                                    placeholder={t("placeholder.city")}
                                    onChange={handleCityChange}
                                    w={["100%", "100%", "50%"]}
                                    errorMessage={t("formError.cityRequired")}
                                  />

                                  <FormControlCountry
                                    label={t("form.country")}
                                    value={address.country}
                                    onChange={handleCountryChange}
                                    w={["100%", "100%", "50%"]}
                                    errorMessage={t(
                                      "formError.countryRequired"
                                    )}
                                  />
                                </Flex>
                                <Flex
                                  align="center"
                                  justify="center"
                                  gap="1rem"
                                >
                                  <Flex
                                    position="relative"
                                    m="0rem 0 0"
                                    justify="center"
                                  >
                                    <Button
                                      bg="rgba(255,255,255,0.3)"
                                      onClick={toggleManualAddressVisible}
                                      color={primary}
                                      variant="link"
                                      fontSize="xs"
                                      fontWeight="500"
                                      p="0.5rem 0.75rem"
                                      borderRadius="10rem"
                                      _hover={{
                                        bg: "rgba(255,255,255,1)",
                                      }}
                                    >
                                      {t("onboarding.enterAddressGoogle")}
                                    </Button>
                                  </Flex>
                                </Flex>
                              </Flex>
                            </>
                          )}
                        </Flex>
                      </Flex>

                      {client.clientType === "individual" && (
                        <Flex
                          w="100%"
                          justify="space-between"
                          p="1.5rem 0"
                          gap={["1rem", "1rem", "2rem"]}
                          borderTop={`1px solid ${newBorder}`}
                          direction={["column", "column", "row"]}
                        >
                          <Stack
                            gap={1}
                            w={["100%", "100%", "300px"]}
                            minW={["100%", "100%", "300px"]}
                            maxW={["100%", "100%", "300px"]}
                          >
                            <Heading fontSize="1.125rem" fontWeight="600">
                              {t("clientAccount.professionSettings")}
                            </Heading>
                            <Text
                              color={textSecondary}
                              fontSize="xs"
                              maxW={["100%", "100%", "300px"]}
                              fontWeight="500"
                            >
                              {t("clientAccount.professionSettingsText")}
                            </Text>
                          </Stack>
                          <Flex
                            w="100%"
                            direction="column"
                            bg={white}
                            borderRadius="1rem"
                            border={`0px solid ${newBorder}`}
                            p="1.5rem 0 0 0"
                          >
                            <FormControlProfessionNew
                              label={t("form.profession")}
                              value={profession}
                              placeholder={t("select.profession")}
                              onChange={handleProfessionChange}
                              w="100%"
                              onProfessionChange={handleProfessionChange}
                            />
                          </Flex>
                        </Flex>
                      )}

                      <Flex
                        w="100%"
                        justify="space-between"
                        p="1.5rem 0"
                        gap="2rem"
                        borderTop={`1px solid ${newBorder}`}
                        direction={["column", "column", "row"]}
                      >
                        <Stack
                          gap={1}
                          w={["100%", "100%", "300px"]}
                          minW={["100%", "100%", "300px"]}
                          maxW={["100%", "100%", "300px"]}
                        >
                          <Heading fontSize="1.125rem" fontWeight="600">
                            {t("clientAccount.notificationSettings")}
                          </Heading>
                          <Text
                            color={textSecondary}
                            fontSize="xs"
                            maxW={["100%", "100%", "300px"]}
                            fontWeight="500"
                          >
                            {t("clientAccount.notificationSettingsText")}
                          </Text>
                        </Stack>
                        <Flex
                          w="100%"
                          direction="column"
                          bg={white}
                          borderRadius="1rem"
                          border={`0px solid ${newBorder}`}
                          p={["0rem 0 0 0", "0rem 0 0 0", "1.5rem 0 0 0"]}
                        >
                          <Flex direction="column" gap="1rem">
                            <Flex
                              align="center"
                              border={`1px solid ${newBorder}`}
                              w={["100%", "100%", "50%"]}
                              p="1rem"
                              borderRadius="1rem"
                              justify="space-between"
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                minW={["160px", "160px", "160px"]}
                                pr="1rem"
                              >
                                {t("clientAccount.emailNotifications")}
                              </Text>

                              <Switch
                                id="emailNotifications"
                                colorScheme="blue"
                                isChecked={notificationEmail}
                                onChange={() => {
                                  setNotificationEmail(!notificationEmail);
                                  setHasChanged(true);
                                }}
                                isDisabled
                              />
                            </Flex>

                            <Flex
                              align="center"
                              border={`1px solid ${newBorder}`}
                              w={["100%", "100%", "50%"]}
                              p="1rem"
                              borderRadius="1rem"
                              justify="space-between"
                            >
                              <Text
                                fontSize="sm"
                                fontWeight="500"
                                minW="160px"
                                pr="1rem"
                              >
                                {t("clientAccount.smsNotifications")}
                              </Text>
                              <Switch
                                id="smsNotifications"
                                colorScheme="blue"
                                isChecked={notificationSms}
                                onChange={() => {
                                  setNotificationSms(!notificationSms);
                                  setHasChanged(true);
                                }}
                                isDisabled
                              />
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <Flex
                  w="100%"
                  direction="column"
                  m="1rem 0 0"
                  p="8rem 0"
                  minH="80vh"
                  bg={white}
                  borderRadius="2rem"
                  align="center"
                  justify="center"
                >
                  <Spinner size="lg" color={primary} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        bg={white}
        borderTop={`1px solid ${newBorder}`}
        w="100%"
        p="3rem 0"
        borderBottom={`1px solid ${newBorder}`}
      >
        <Flex w="100%" maxW="1200px" m="0 auto">
          <Faq />
        </Flex>
      </Flex>

      <Footer />
    </>
  );
};

export default UserAccountSettings;
