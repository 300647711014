import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  IconButton,
  Select,
  HStack,
} from "@chakra-ui/react";
import { textPrimary, textSecondary } from "../../utils/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import moment from "moment";

dayjs.extend(utc);

const FormControlDatePickerRangeDouble = ({
  isRequired,
  label,
  placeholder,
  onChange,
  isError,
  errorMessage,
  isSmall,
  minDate,
  startDate,
  endDate,
  setDefaultStartTime,
  startDateTouched,
  setStartDateTouched,
  setEndDateTouched,
}) => {
  // CONSTANTS
  // Convert startDate and endDate to UTC on load
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);

  // Initialize startDateState and endDateState as dayjs instances in UTC
  // const [startDateState, setStartDateState] = useState(
  //   startDate ? dayjs(startDate).utc() : null
  // );
  // const [endDateState, setEndDateState] = useState(
  //   endDate ? dayjs(endDate).utc() : null
  // );

  // Generate a range of years from 5 years before to 5 years after the current year
  const currentYear = dayjs().year();
  const years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);

  const months = [
    t("months.january"),
    t("months.february"),
    t("months.march"),
    t("months.april"),
    t("months.may"),
    t("months.june"),
    t("months.july"),
    t("months.august"),
    t("months.september"),
    t("months.october"),
    t("months.november"),
    t("months.december"),
  ];

  // HANDLERS

  // const handleStartDateChange = (date) => {
  //   if (!date) {
  //     setStartDateState(null); // Clear the state when date is null
  //     onChange(null, endDateState ? endDateState.toISOString() : null);
  //     setStartDateTouched(true);
  //     return;
  //   }

  //   console.log("HANDLER DATE:", date);

  //   // const adjustedDate = dayjs.utc(date).startOf("day"); // Set to UTC midnight
  //   // setStartDateState(adjustedDate);
  //   setStartDateState(date); // Pass date as-is

  //   const isEndDateBeforeStartDate =
  //     endDateState && dayjs.utc(endDateState).isBefore(date);
  //   if (!endDateState || isEndDateBeforeStartDate) {
  //     onChange(date.toISOString(), date.toISOString());
  //   } else {
  //     onChange(date.toISOString(), endDateState?.toISOString());
  //   }

  //   if (setDefaultStartTime) {
  //     setDefaultStartTime();
  //   }

  //   setStartDateTouched(true);
  // };

  // const handleEndDateChange = (date) => {
  //   if (!date) {
  //     setEndDateState(null); // Clear the state when date is null
  //     onChange(startDateState ? startDateState.toISOString() : null, null);
  //     setEndDateTouched(true);
  //     return;
  //   }

  //   // const adjustedDate = dayjs.utc(date).startOf("day"); // Set to UTC midnight
  //   // setEndDateState(adjustedDate);
  //   setEndDateState(date); // Pass date as-is

  //   if (!startDateState || dayjs.utc(startDateState).isAfter(date)) {
  //     onChange(date.toISOString(), date.toISOString());
  //     if (setDefaultStartTime) {
  //       setDefaultStartTime();
  //     }
  //   } else {
  //     onChange(startDateState?.toISOString(), date.toISOString());
  //   }

  //   setEndDateTouched(true);
  // };

  // const handleStartDateChange = (date) => {
  //   if (!date) {
  //     setStartDateState(null); // Clear the state when date is null
  //     onChange(null, endDateState ? endDateState.toISOString() : null);
  //     setStartDateTouched(true);
  //     return;
  //   }

  //   console.log("HANDLER DATE:", date);

  //   // Clone the date and apply the timezone adjustment as we did in `useEffect`
  //   const selectedMoment = moment(date);
  //   const utcMoment = selectedMoment.clone().utc();
  //   const modifiedDate = selectedMoment.subtract(
  //     selectedMoment.utcOffset() - utcMoment.utcOffset(),
  //     "minutes"
  //   );

  //   // Set the adjusted date in the state
  //   setStartDateState(new Date(modifiedDate.format("YYYY-MM-DDTHH:mm:ss")));

  //   console.log("MODIFIED DATE:", modifiedDate);

  //   // Call onChange with adjusted dates
  //   const isEndDateBeforeStartDate =
  //     endDateState && moment(endDateState).isBefore(modifiedDate);
  //   if (!endDateState || isEndDateBeforeStartDate) {
  //     onChange(modifiedDate.toISOString(), modifiedDate.toISOString());
  //   } else {
  //     onChange(modifiedDate.toISOString(), endDateState?.toISOString());
  //   }

  //   if (setDefaultStartTime) {
  //     setDefaultStartTime();
  //   }

  //   setStartDateTouched(true);
  // };

  const handleStartDateChange = (date) => {
    if (!date) {
      setStartDateState(null);
      onChange(null, endDateState ? endDateState.toISOString() : null);
      setStartDateTouched(true);
      return;
    }

    console.log("HANDLER DATE:", date);

    const modifiedDate = moment.utc(date).toDate(); // Force UTC conversion

    console.log("MODIFIED DATE:", modifiedDate);

    setStartDateState(modifiedDate);

    const isEndDateBeforeStartDate =
      endDateState && moment.utc(endDateState).isBefore(modifiedDate);
    if (!endDateState || isEndDateBeforeStartDate) {
      onChange(modifiedDate.toISOString(), modifiedDate.toISOString());
    } else {
      onChange(modifiedDate.toISOString(), endDateState?.toISOString());
    }

    if (setDefaultStartTime) {
      setDefaultStartTime();
    }

    setStartDateTouched(true);
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      setEndDateState(null); // Clear the state when date is null
      onChange(startDateState ? startDateState.toISOString() : null, null);
      setEndDateTouched(true);
      return;
    }

    // Clone the date and apply the timezone adjustment as we did in `useEffect`
    const selectedMoment = moment(date);
    const utcMoment = selectedMoment.clone().utc();
    const modifiedDate = selectedMoment.subtract(
      selectedMoment.utcOffset() - utcMoment.utcOffset(),
      "minutes"
    );

    // Set the adjusted date in the state
    setEndDateState(new Date(modifiedDate.format("YYYY-MM-DDTHH:mm:ss")));

    // Call onChange with adjusted dates
    if (!startDateState || moment(startDateState).isAfter(modifiedDate)) {
      onChange(modifiedDate.toISOString(), modifiedDate.toISOString());
      if (setDefaultStartTime) {
        setDefaultStartTime();
      }
    } else {
      onChange(startDateState?.toISOString(), modifiedDate.toISOString());
    }

    setEndDateTouched(true);
  };

  // USE EFFECT
  // useEffect(() => {
  //   if (startDate) {
  //     setStartDateState(startDate); // Set to UTC midnight
  //     // setStartDateState(dayjs(startDate).utc().startOf("day")); // Set to UTC midnight
  //   }
  //   if (endDate) {
  //     setEndDateState(endDate); // Set to UTC midnight
  //     // setEndDateState(dayjs(endDate).utc().startOf("day")); // Set to UTC midnight
  //   }
  // }, [startDate, endDate]);
  // useEffect(() => {
  //   if (startDate) {
  //     setStartDateState(new Date(startDate)); // Ensure this is a Date object
  //   }
  //   if (endDate) {
  //     setEndDateState(new Date(endDate)); // Ensure this is a Date object
  //   }
  // }, [startDate, endDate]);
  // useEffect(() => {
  //   if (startDate) {
  //     const startMoment = moment(startDate); // Original start date in the user's local timezone
  //     const startUTC = startMoment.clone().utc(); // Start date as UTC
  //     const modifiedStart = moment(startDate).subtract(
  //       startMoment.utcOffset() - startUTC.utcOffset(),
  //       "minutes"
  //     ); // Adjust for timezone difference
  //     setStartDateState(new Date(modifiedStart.format("YYYY-MM-DDTHH:mm:ss")));
  //   }

  //   if (endDate) {
  //     const endMoment = moment(endDate); // Original end date in the user's local timezone
  //     const endUTC = endMoment.clone().utc(); // End date as UTC
  //     const modifiedEnd = moment(endDate).subtract(
  //       endMoment.utcOffset() - endUTC.utcOffset(),
  //       "minutes"
  //     ); // Adjust for timezone difference
  //     setEndDateState(new Date(modifiedEnd.format("YYYY-MM-DDTHH:mm:ss")));
  //   }
  // }, [startDate, endDate]);
  useEffect(() => {
    if (startDate) {
      const modifiedStart = moment.utc(startDate).toDate(); // Force UTC conversion
      setStartDateState(modifiedStart);
    }

    if (endDate) {
      const modifiedEnd = moment.utc(endDate).toDate(); // Force UTC conversion
      setEndDateState(modifiedEnd);
    }
  }, [startDate, endDate]);

  // LOGS

  console.log("StartDate in DatePicker:", startDate);
  console.log("EndDate in DatePicker:", endDate);

  console.log("startDateState in DatePicker:", startDateState);
  console.log("endDateState in DatePicker:", endDateState);

  console.log(
    "startDateState in DatePicker (UTC):",
    startDateState ? startDateState.toISOString() : null
  );
  console.log(
    "endDateState in DatePicker (UTC):",
    endDateState ? endDateState.toISOString() : null
  );

  return (
    <>
      <FormControl
        mb={4}
        isRequired={isRequired}
        isInvalid={startDateTouched && isError}
        w="100%"
        className="customDatePicker"
      >
        <FormLabel
          fontSize={isSmall ? "xs" : "sm"}
          position="relative"
          color={textPrimary}
        >
          {label}
          {isError && startDateTouched && (
            <FormErrorMessage
              fontSize="xs"
              position="absolute"
              top={-1}
              right={0}
              zIndex={2}
            >
              {errorMessage}
            </FormErrorMessage>
          )}
        </FormLabel>

        <HStack spacing={2} mb={0} w="100%">
          <Box className="popoverDatePicker singleDatepicker" mr={2} w="50%">
            <DatePicker
              selected={startDateState ? startDateState : null}
              onChange={handleStartDateChange}
              shouldCloseOnSelect={true}
              selectsStart
              utcOffset={0}
              startDate={startDateState ? startDateState : null}
              endDate={endDateState ? endDateState : null}
              minDate={minDate ? new Date(minDate) : null}
              //   maxDate={endDate}
              dateFormat="yyyy-MM-dd" // Specify the format here
              placeholderText={t("placeholder.startDate")}
              onBlur={(e) => {
                e.preventDefault();
                // console.log("Calendar closed"); // Call onClickOutside handler passed from parent component
              }}
              onClickOutside={() => setStartDateTouched(true)}
              // onCalendarClose={(e) => {
              //   e.preventDefault();
              //   console.log("Calendar closed");
              // }}
              className={`react-datepicker__input-container ${
                isError && startDateTouched
                  ? "react-datepicker__input-container--invalid"
                  : ""
              } ${isSmall ? "isSmall" : ""}`}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Flex
                  m="1rem auto 1rem"
                  align="center"
                  justify="center"
                  w="100%"
                  px={5}
                >
                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleLeft />}
                    bg="none"
                    onClick={decreaseMonth}
                    color={textSecondary}
                    isDisabled={prevMonthButtonDisabled}
                  />
                  <Flex align="center" mx={2}>
                    <Select
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="40px"
                      maxWidth="80px"
                      borderRadius="0.75rem"
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      ml={1}
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="100px"
                      maxWidth="200px"
                      borderRadius="0.75rem"
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleRight />}
                    bg="none"
                    onClick={increaseMonth}
                    color={textSecondary}
                    isDisabled={nextMonthButtonDisabled}
                  />
                </Flex>
              )}
            />
          </Box>
          <Box className="popoverDatePicker singleDatepicker" w="50%">
            <DatePicker
              selected={endDateState ? endDateState : null}
              onChange={handleEndDateChange}
              selectsEnd
              utcOffset={0}
              startDate={startDateState ? startDateState : null}
              endDate={endDateState ? endDateState : null}
              minDate={startDateState ? startDateState : null}
              //   maxDate={new Date()} // or another max date
              onClickOutside={() => setStartDateTouched(true)}
              placeholderText={placeholder}
              dateFormat="yyyy-MM-dd"
              onBlur={(e) => {
                e.preventDefault();
                // console.log("Calendar closed"); // Call onClickOutside handler passed from parent component
              }}
              className={`react-datepicker__input-container ${
                isError && startDateTouched
                  ? "react-datepicker__input-container--invalid"
                  : ""
              } ${isSmall ? "isSmall" : ""}`}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Flex
                  m="1rem auto 1rem"
                  align="center"
                  justify="center"
                  w="100%"
                  px={5}
                >
                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleLeft />}
                    bg="none"
                    onClick={decreaseMonth}
                    color={textSecondary}
                    isDisabled={prevMonthButtonDisabled}
                  />
                  <Flex align="center" mx={2}>
                    <Select
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="40px"
                      maxWidth="80px"
                      borderRadius="0.75rem"
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      ml={1}
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="100px"
                      maxWidth="200px"
                      borderRadius="0.75rem"
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleRight />}
                    bg="none"
                    onClick={increaseMonth}
                    color={textSecondary}
                    isDisabled={nextMonthButtonDisabled}
                  />
                </Flex>
              )}
            />
          </Box>
        </HStack>
      </FormControl>
    </>
  );
};

export default FormControlDatePickerRangeDouble;
