import { useEffect } from "react";
import styled from "styled-components";
import { icons } from "../../../utils/icons";
import { mobile, big } from "../../../utils/breakpoints";
// import { Container, SectionTitle, H6 } from "..";
import { images } from "../../../utils/images";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation, Scrollbar, EffectFade } from "swiper";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import {
  secondary,
  primary,
  grey,
  text,
  white,
  textSecondary,
  newBorder,
  backgroundLight,
} from "../../../utils/colors";
import { slideFromLeftTransition } from "../../../assets/animations/slideFromLeftTransition";
import { slideFromRightTransition } from "../../../assets/animations/slideFromRightTransition";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Heading,
  Text,
  Stack,
  Button,
  Icon,
  Box,
  Tooltip,
} from "@chakra-ui/react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import usecase1 from "../../../assets/images/usecase1.png";
import usecase2 from "../../../assets/images/usecase2.png";
import usecase3 from "../../../assets/images/usecase3.png";

// SwiperCore.use([Navigation, Scrollbar, EffectFade]);

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const params = {
  loop: true,
  navigation: {
    nextEl: ".arrow-right",
    prevEl: ".arrow-left",
  },

  breakpoints: {
    320: {
      slidesPerView: 1,
      spaceBetween: 40,
    },
    1023: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    1680: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
    2560: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  },
};

export const UseCasesCarousel = () => {
  let { t } = useTranslation("");

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  const isMobile = useMediaQuery({ query: mobile });

  return (
    <Flex
      as={motion.div}
      ref={ref}
      variants={slideFromLeftTransition}
      initial="hidden"
      animate={control}
      w="100%"
      p={["4rem 1.5rem", "4rem 1.5rem", "8rem 0"]}
      position="relative"
      background={white}
      borderTop={[
        `1px solid ${newBorder}`,
        `1px solid ${newBorder}`,
        `1px solid ${newBorder}`,
      ]}
    >
      <Flex
        w="100%"
        direction="column"
        maxW={["100%", "100%", "1200px"]}
        m="0 auto"
      >
        <Box w="100%" position="relative">
          <Flex
            w="100%"
            align="flex-end"
            justify={["flex-start", "flex-start", "space-between"]}
            m="0 0 3rem"
            direction={["column", "column", "row"]}
          >
            <Flex
              as={motion.div}
              ref={ref}
              variants={slideFromLeftTransition}
              initial="hidden"
              animate={control}
              w="100%"
            >
              <Stack gap="1rem" w={["100%", "100%", "initial"]}>
                <Heading fontSize={["2rem", "2rem", "3rem"]} fontWeight="700">
                  {t("landingMegagen.useCasesTitle")} <br />
                </Heading>
                <Text fontWeight="500" color={textSecondary}>
                  {t("landingMegagen.useCasesText")}
                </Text>
              </Stack>
            </Flex>

            <Flex
              as={motion.div}
              ref={ref}
              variants={slideFromRightTransition}
              initial="hidden"
              animate={control}
              display={["none", "none", "flex"]}
            >
              <Flex gap="1rem">
                <Button
                  size="lg"
                  bg={primary}
                  borderRadius="50%"
                  flexShrink={0}
                  w="3rem"
                  h="3rem"
                >
                  <Icon as={FaArrowLeft} color={white} fontSize="1rem" />
                </Button>
                <Button
                  size="lg"
                  bg={primary}
                  borderRadius="50%"
                  flexShrink={0}
                  w="3rem"
                  h="3rem"
                >
                  <Icon as={FaArrowRight} color={white} fontSize="1rem" />
                </Button>
              </Flex>
            </Flex>
          </Flex>

          <Swiper {...params} className="use-cases-swiper">
            <SwiperSlide>
              <UseCase>
                <Tooltip
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  label={t("landingMegagen.articleSoon")}
                  placement="top"
                >
                  <Flex overflow="hidden" w="100%" h="100%" borderRadius="2rem">
                    <Image src={usecase1} />
                  </Flex>
                </Tooltip>
                <Flex direction="column" p="1.5rem 1rem">
                  <Text
                    color={primary}
                    fontWeight="600"
                    textTransform="uppercase"
                    fontSize="sm"
                  >
                    {t("landingMegagen.useCase1Category")}
                  </Text>
                  <Heading fontSize="1.25rem" fontWeight="600" m="1rem 0 1rem">
                    {t("landingMegagen.useCase1Title")}
                  </Heading>
                  <Flex w="100%">
                    <Tooltip
                      p="0.5rem 1rem"
                      borderRadius="1rem"
                      label={t("landingMegagen.articleSoon")}
                      placement="top"
                    >
                      <Button
                        size="sm"
                        bg={backgroundLight}
                        color={textSecondary}
                        borderRadius="1rem"
                        fontWeight="600"
                        cursor="pointer"
                        isDisabled
                      >
                        {t("landingMegagen.readMore")}
                        <Icon as={FaArrowRight} ml="0.5rem" />
                      </Button>
                    </Tooltip>
                  </Flex>
                  {/* <Text fontSize="1rem" fontWeight="500" color={textSecondary}>
                    {t("landingMegagen.useCase1Subtitle")}
                  </Text> */}
                </Flex>
              </UseCase>
            </SwiperSlide>
            <SwiperSlide>
              <UseCase>
                <Tooltip
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  label={t("landingMegagen.articleSoon")}
                  placement="top"
                >
                  <Flex overflow="hidden" w="100%" h="100%" borderRadius="2rem">
                    <Image src={usecase2} />
                  </Flex>
                </Tooltip>
                <Flex direction="column" p="1.5rem 1rem">
                  <Text
                    color={primary}
                    fontWeight="600"
                    textTransform="uppercase"
                    fontSize="sm"
                  >
                    {t("landingMegagen.useCase2Category")}
                  </Text>
                  <Heading fontSize="1.25rem" fontWeight="600" m="1rem 0 1rem">
                    {t("landingMegagen.useCase2Title")}
                  </Heading>
                  <Flex w="100%">
                    <Tooltip
                      p="0.5rem 1rem"
                      borderRadius="1rem"
                      label={t("landingMegagen.articleSoon")}
                      placement="top"
                    >
                      <Button
                        size="sm"
                        bg={backgroundLight}
                        color={textSecondary}
                        borderRadius="1rem"
                        fontWeight="600"
                        cursor="pointer"
                        isDisabled
                      >
                        {t("landingMegagen.readMore")}
                        <Icon as={FaArrowRight} ml="0.5rem" />
                      </Button>
                    </Tooltip>
                  </Flex>
                  {/* <Text fontSize="1rem" fontWeight="500" color={textSecondary}>
                    {t("landingMegagen.useCase2Subtitle")}
                  </Text> */}
                </Flex>
              </UseCase>
            </SwiperSlide>
            <SwiperSlide>
              <UseCase>
                <Tooltip
                  p="0.5rem 1rem"
                  borderRadius="1rem"
                  label={t("landingMegagen.articleSoon")}
                  placement="top"
                >
                  <Flex overflow="hidden" w="100%" h="100%" borderRadius="2rem">
                    <Image src={usecase3} />
                  </Flex>
                </Tooltip>
                <Flex direction="column" p="1.5rem 1rem">
                  <Text
                    color={primary}
                    fontWeight="600"
                    textTransform="uppercase"
                    fontSize="sm"
                  >
                    {t("landingMegagen.useCase3Category")}
                  </Text>
                  <Heading fontSize="1.25rem" fontWeight="600" m="1rem 0 1rem">
                    {t("landingMegagen.useCase3Title")}
                  </Heading>
                  <Flex w="100%">
                    <Tooltip
                      p="0.5rem 1rem"
                      borderRadius="1rem"
                      label={t("landingMegagen.articleSoon")}
                      placement="top"
                    >
                      <Button
                        size="sm"
                        bg={backgroundLight}
                        color={textSecondary}
                        borderRadius="1rem"
                        fontWeight="600"
                        cursor="pointer"
                        isDisabled
                      >
                        {t("landingMegagen.readMore")}
                        <Icon as={FaArrowRight} ml="0.5rem" />
                      </Button>
                    </Tooltip>
                  </Flex>
                  {/* <Text fontSize="1rem" fontWeight="500" color={textSecondary}>
                    {t("landingMegagen.useCase3Subtitle")}
                  </Text> */}
                </Flex>
              </UseCase>
            </SwiperSlide>
          </Swiper>
        </Box>
      </Flex>
    </Flex>
  );
};

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0rem;
  align-items: center;
`;

const Tag = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border: 1px solid ${grey};
  border-radius: 100px;
  padding: 8px 12px;
  margin-right: 8px;
  color: ${text};
  font-weight: 500;
  font-size: 13px;

  &.primary {
    border: 2px solid ${primary};
    color: ${primary};
  }
`;

const ButtonIcon = styled.span`
  width: 14px;
  position: absolute;
  right: 0;
  height: 18px;

  &:before {
    transform: translateX(-20px);
    content: url(${icons.small_button_arrow});
    position: absolute;
    opacity: 0;
  }
  &:after {
    content: url(${icons.small_button_arrow});
    position: absolute;
    opacity: 1;
  }
  @media ${mobile} {
    right: 5px;
  }
`;

const SmallButton = styled.a`
  border: 0px solid #fff;
  background: transparent;
  color: ${secondary};
  font-weight: 600;
  font-size: 16px;
  padding: 16px 73px 16px 0;
  border-radius: 0px;
  transition: background 0.2s;
  cursor: pointer;
  display: flex;
  position: relative;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0 0;
  width: 100%;
  text-align: left;
  &:before {
    content: "";
    height: 1px;
    width: 100%;
    background: #0c2722;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: skewX(-60deg);
    -webkit-transform: skewX(-60deg);
    -moz-transform: skewX(-60deg);
  }
  &:after {
    content: "";
    height: 1px;
    width: 20px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: -20px;
    transform: skewX(-60deg);
    -webkit-transform: skewX(-60deg);
    -moz-transform: skewX(-60deg);
    transition: 0.6s all;
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  @media ${mobile} {
    margin: 0 0 2.5rem;
  }
`;

const TopLeft = styled.div`
  width: 50%;
  @media ${mobile} {
    width: 100%;
  }
`;
const TopRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media ${mobile} {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  transform: scale(1);
  object-fit: cover;
  transition: 0.3s all;

  @media ${mobile} {
  }
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: 0.3s all;
  padding: 0 1rem;

  @media ${mobile} {
    padding: 0;
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 270px;
  overflow: hidden;
  border-radius: 26px;

  @media ${mobile} {
    border-radius: 18px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  font-size: 14px;
  margin-top: 2rem;
  color: ${text};
  font-weight: 500;
  transition: 0.3s all;
  @media ${mobile} {
  }
`;
const UseCase = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
  }
  &:hover {
    ${InfoWrapper} {
      /*     padding-left: 0px; */
      transition: 0.3s all;
    }
    ${Image} {
      transform: scale(1.05);
      transition: 0.3s all;
    }
    ${SmallButton} {
      &:after {
        left: 100%;
        transition: 0.6s all;
      }
      ${ButtonIcon} {
        &:before {
          opacity: 1;
          right: 0;
          transition: transform 0.5s ease 0.4s, opacity 0.5s ease 0.4s;
          transform: translateX(0);
        }
        &:after {
          opacity: 0;
          transition: transform 0.5s ease 0ms, opacity 0.5s ease 0ms;
          transform: translateX(20px);
        }
      }
    }
  }
  @media ${mobile} {
  }
`;

const Category = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${primary};
  margin: 36px 0 16px;
  text-transform: uppercase;

  @media ${mobile} {
  }
  @media ${big} {
    font-size: 13px;
  }
`;

const InnerWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;
const ButtonNavIcon = styled.img`
  height: 14px;
  opacity: 0.6;
`;
const ButtonNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  background: ${primary};
  border-radius: 20rem;
  margin: ${(props) => props.margin || "0"};
  transition: 0.2s all;

  @media ${mobile} {
    height: 48px;
    width: 48px;
  }
  &.swiper-button-disabled {
    opacity: 0.5;
  }
  &:hover {
    background: ${secondary};
    cursor: pointer;
    transition: 0.2s all;
  }
  ${ButtonNavIcon} {
    opacity: 1;
    transition: 0.2s all;
  }
`;

export default UseCasesCarousel;
