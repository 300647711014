import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import {
  white,
  newBorder,
  textPrimary,
  primary,
  textSecondary,
} from "../../utils/colors";
import Select from "react-select";
import CountriesData from "../../data/countries.json";
import { useTranslation } from "react-i18next";

const FormControlCountry = ({
  label,
  value,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  mb,
  w,
}) => {
  const { t } = useTranslation();

  const countries = CountriesData;

  // Sort countries alphabetically by their common name
  const sortedCountries = CountriesData.sort((a, b) =>
    a.name.common.localeCompare(b.name.common)
  );

  // Modify the country options to include the flag and sort them
  const countryOptions = [
    { value: "", label: t("placeholder.allCountries") }, // Placeholder
    ...sortedCountries.map((country) => ({
      value: country.cca2,
      label: country.name.common,
      flag: country.flags.png, // or svg, depending on your preference
    })),
  ];

  // Custom rendering for options
  const formatOptionLabel = ({ label, flag }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {flag && (
        <img
          src={flag}
          alt={label}
          style={{ marginRight: 10, width: 20, height: 15 }}
        />
      )}
      {label}
    </div>
  );

  // console.log("countryOptions", countryOptions);

  return (
    <FormControl
      mb={mb ? mb : [0, 0, 4]}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize="sm" position="relative" color={textPrimary}>
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={countryOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={countryOptions}
        placeholder={t("placeholder.allCountries")}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderColor: isError
              ? "red"
              : state.isFocused
              ? newBorder
              : newBorder,

            color: textSecondary,
            borderRadius: "1rem",
            backgroundColor: `${white}`,
            border: `1px solid ${newBorder}`,
            fontSize: "0.938rem",
            fontWeight: "500",
            height: "3rem",
            padding: "0 0.25rem",
            ":focus-visible": {
              borderColor: primary,
              boxShadow: `0 0 0 1px ${newBorder}`,
            },
          }),
          menu: (provided, state) => ({
            ...provided,
          }),

          option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? "white" : "black", // Color of text in options
            backgroundColor: state.isSelected ? primary : white, // Background color of selected option
            ":hover": {
              backgroundColor: primary, // Background color when hovering
              color: "white",
            },
          }),

          singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = "opacity 300ms";

            // check if the value is null (placeholder)
            const color =
              state.data.value === "" ? "desired color" : "default color";

            return { ...provided, opacity, transition, color };
          },
        }}
      />
    </FormControl>
  );
};

export default FormControlCountry;
