import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../pages/general/Login";
import NotFound from "../pages/general/NotFound";
import CoursesPublic from "../pages/public/courses/CoursesPublic";
import Landing from "../pages/landing/Landing";
import CourseSinglePublic from "../pages/public/courses/single/CourseSinglePublic";
import Register from "../pages/general/Register";
import UserAccount from "../pages/client/account/UserAccount";
import ImplantFailForm from "../pages/client/implant-fail-form/ImplantFailForm/ImplantFailForm";
import UserReports from "../pages/client/account/reports/list/UserReports";
import UserEvents from "../pages/client/account/events/list/UserEvents";
import UserEmployees from "../pages/client/account/employees/list/UserEmployees";
import UserAccountSettings from "../pages/client/account/settings/UserAccountSettings";
import UserSingleReport from "../pages/client/account/reports/single/UserSingleReport";
import UserSingleEvent from "../pages/client/account/events/single/UserSingleEvent";
import VerifyRegistration from "../pages/general/VerifyRegistration";
import VerifyPublicRegisterToken from "../pages/public/courses/VerifyPublicRegisterToken";
import ResetPassword from "../pages/general/ResetPassword";
import Offers from "../pages/public/custom/offers/Offers";

const PrivateRoutes = ({ userInfo }) => {
  return (
    <Routes>
      {/* General Routes */}
      <Route path="/" element={<Landing />} />

      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/verify-token" element={<VerifyRegistration />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/verify-event-register"
        element={<VerifyPublicRegisterToken />}
      />

      <Route
        path="/implant-fail-form"
        element={<ImplantFailForm userInfo={userInfo} />}
      />

      <Route path="/account" element={<UserAccount />} />
      <Route path="/account/forms" element={<UserReports />} />
      <Route path="/account/forms/:id" element={<UserSingleReport />} />
      <Route path="/account/events" element={<UserEvents />} />
      <Route path="/account/events/:id" element={<UserSingleEvent />} />
      <Route path="/account/employees" element={<UserEmployees />} />

      {/* Admin Routes */}
      <Route
        path="/account/settings"
        element={<UserAccountSettings userInfo={userInfo} />}
      />

      {/* Admin Routes */}

      <Route path="/events" element={<CoursesPublic />} />
      <Route path="/events/:id" element={<CourseSinglePublic />} />
      <Route path="/offers" element={<Offers />} />

      {/* Catch-all route for 404 page */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default PrivateRoutes;
