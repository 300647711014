import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
  IconButton,
  Select,
  HStack,
} from "@chakra-ui/react";
import { textPrimary, textSecondary } from "../../utils/colors";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { t } from "i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import moment from "moment";

dayjs.extend(utc);

const FormControlDatePickerRangeDoubleEdit = ({
  isRequired,
  label,
  placeholder,
  onChange,
  isError,
  errorMessage,
  isSmall,
  minDate,
  startDate,
  endDate,
  setDefaultStartTime,
  startDateTouched,
  setStartDateTouched,
  setEndDateTouched,
  timezone,
  timezoneOffset,
}) => {
  // CONSTANTS
  // Convert startDate and endDate to UTC on load
  const [startDateState, setStartDateState] = useState(null);
  const [endDateState, setEndDateState] = useState(null);

  // Initialize startDateState and endDateState as dayjs instances in UTC
  // const [startDateState, setStartDateState] = useState(
  //   startDate ? dayjs(startDate).utc() : null
  // );
  // const [endDateState, setEndDateState] = useState(
  //   endDate ? dayjs(endDate).utc() : null
  // );

  // Generate a range of years from 5 years before to 5 years after the current year
  const currentYear = dayjs().year();
  const years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);

  const months = [
    t("months.january"),
    t("months.february"),
    t("months.march"),
    t("months.april"),
    t("months.may"),
    t("months.june"),
    t("months.july"),
    t("months.august"),
    t("months.september"),
    t("months.october"),
    t("months.november"),
    t("months.december"),
  ];

  // HANDLERS

  const handleStartDateChange = (date) => {
    if (!date) {
      // If the date is cleared, update the state and call onChange with null
      setStartDateState(null);
      onChange(null, endDateState ? endDateState.toISOString() : null);
      setStartDateTouched(true);
      return;
    }

    console.log("HANDLER DATE:", date);

    // Get the user's timezone offset in minutes
    const userOffsetMinutes = new Date().getTimezoneOffset();

    // Adjust the date for the user's timezone offset
    const adjustedDate = moment(date)
      .subtract(userOffsetMinutes, "minutes")
      .toDate();

    setStartDateState(adjustedDate);
    console.log("Adjusted Start Date:", adjustedDate);

    // Adjust end date state if it exists
    const adjustedEndDateState = endDateState
      ? moment(endDateState).subtract(userOffsetMinutes, "minutes").toDate()
      : null;

    // Update the onChange with adjusted dates
    onChange(
      adjustedDate.toISOString(),
      adjustedEndDateState ? adjustedEndDateState.toISOString() : null
    );

    if (setDefaultStartTime) {
      setDefaultStartTime();
    }

    setStartDateTouched(true);
  };

  const handleEndDateChange = (date) => {
    if (!date) {
      // If the date is cleared, update the state and call onChange with null
      setEndDateState(null);
      onChange(startDateState ? startDateState.toISOString() : null, null);
      setEndDateTouched(true);
      return;
    }

    console.log("HANDLER DATE ENDDATE:", date);

    // Get the user's timezone offset in minutes
    const userOffsetMinutes = new Date().getTimezoneOffset();

    // Adjust the date for the user's timezone offset
    const adjustedDate = moment(date)
      .subtract(userOffsetMinutes, "minutes")
      .toDate();

    setEndDateState(adjustedDate);
    console.log("Adjusted End Date:", adjustedDate);

    // Adjust start date state if it exists
    const adjustedStartDateState = startDateState
      ? moment(startDateState).subtract(userOffsetMinutes, "minutes").toDate()
      : null;

    // Update the onChange with adjusted dates
    onChange(
      adjustedStartDateState ? adjustedStartDateState.toISOString() : null,
      adjustedDate.toISOString()
    );

    setEndDateTouched(true);
  };

  // LOGS
  useEffect(() => {
    const userOffsetMinutes = new Date().getTimezoneOffset();

    if (startDate) {
      const adjustedStartDate = moment(startDate)
        .add(userOffsetMinutes, "minutes")
        .toDate();
      setStartDateState(adjustedStartDate);
    }

    if (endDate) {
      const adjustedEndDate = moment(endDate)
        .add(userOffsetMinutes, "minutes")
        .toDate();
      setEndDateState(adjustedEndDate);
    }
  }, [startDate, endDate]);

  console.log("StartDate :", startDate);
  console.log("EndDate :", endDate);

  console.log("startDateState in DatePicker:", startDateState);
  console.log("endDateState in DatePicker:", endDateState);

  // console.log(
  //   "startDateState in DatePicker (UTC):",
  //   startDateState ? startDateState.toISOString() : null
  // );
  // console.log(
  //   "endDateState in DatePicker (UTC):",
  //   endDateState ? endDateState.toISOString() : null
  // );

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log("User's Timezone:", userTimeZone);

  const userTimezoneOffsetMinutes = new Date().getTimezoneOffset();

  // const date = new Date();
  // const timezoneOffset = -date.getTimezoneOffset() / 60;
  // const gmtOffset = `GMT${timezoneOffset >= 0 ? "+" : ""}${timezoneOffset}`;
  // console.log("User's GMT Offset:", gmtOffset);
  // console.log("User's Timezone Offset:", timezoneOffsetMinutes);

  console.log("course timezone:", timezone);
  console.log("course timezone offset:", timezoneOffset);

  console.log("user timezone:", userTimeZone);
  console.log("user timezone offset:", userTimezoneOffsetMinutes);

  return (
    <>
      <FormControl
        mb={4}
        isRequired={isRequired}
        isInvalid={startDateTouched && isError}
        w="100%"
        className="customDatePicker"
      >
        <FormLabel
          fontSize={isSmall ? "xs" : "sm"}
          position="relative"
          color={textPrimary}
        >
          {label}
          {isError && startDateTouched && (
            <FormErrorMessage
              fontSize="xs"
              position="absolute"
              top={-1}
              right={0}
              zIndex={2}
            >
              {errorMessage}
            </FormErrorMessage>
          )}
        </FormLabel>

        <HStack spacing={2} mb={0} w="100%">
          <Box className="popoverDatePicker singleDatepicker" mr={2} w="50%">
            <DatePicker
              selected={startDateState ? startDateState : null}
              onChange={handleStartDateChange}
              shouldCloseOnSelect={true}
              selectsStart
              utcOffset={0} // Force UTC offset
              startDate={startDateState ? startDateState : null}
              endDate={endDateState ? endDateState : null}
              minDate={minDate ? new Date(minDate) : null}
              //   maxDate={endDate}
              dateFormat="yyyy-MM-dd" // Specify the format here
              placeholderText={t("placeholder.startDate")}
              onBlur={(e) => {
                e.preventDefault();
                // console.log("Calendar closed"); // Call onClickOutside handler passed from parent component
              }}
              onClickOutside={() => setStartDateTouched(true)}
              // onCalendarClose={(e) => {
              //   e.preventDefault();
              //   console.log("Calendar closed");
              // }}
              className={`react-datepicker__input-container ${
                isError && startDateTouched
                  ? "react-datepicker__input-container--invalid"
                  : ""
              } ${isSmall ? "isSmall" : ""}`}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Flex
                  m="1rem auto 1rem"
                  align="center"
                  justify="center"
                  w="100%"
                  px={5}
                >
                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleLeft />}
                    bg="none"
                    onClick={decreaseMonth}
                    color={textSecondary}
                    isDisabled={prevMonthButtonDisabled}
                  />
                  <Flex align="center" mx={2}>
                    <Select
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="40px"
                      maxWidth="80px"
                      borderRadius="0.75rem"
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      ml={1}
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="100px"
                      maxWidth="200px"
                      borderRadius="0.75rem"
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleRight />}
                    bg="none"
                    onClick={increaseMonth}
                    color={textSecondary}
                    isDisabled={nextMonthButtonDisabled}
                  />
                </Flex>
              )}
            />
          </Box>
          <Box className="popoverDatePicker singleDatepicker" w="50%">
            <DatePicker
              selected={endDateState ? endDateState : null}
              onChange={handleEndDateChange}
              selectsEnd
              utcOffset={0}
              startDate={startDateState ? startDateState : null}
              endDate={endDateState ? endDateState : null}
              minDate={startDateState ? startDateState : null}
              //   maxDate={new Date()} // or another max date
              onClickOutside={() => setStartDateTouched(true)}
              placeholderText={placeholder}
              dateFormat="yyyy-MM-dd"
              onBlur={(e) => {
                e.preventDefault();
                // console.log("Calendar closed"); // Call onClickOutside handler passed from parent component
              }}
              className={`react-datepicker__input-container ${
                isError && startDateTouched
                  ? "react-datepicker__input-container--invalid"
                  : ""
              } ${isSmall ? "isSmall" : ""}`}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <Flex
                  m="1rem auto 1rem"
                  align="center"
                  justify="center"
                  w="100%"
                  px={5}
                >
                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleLeft />}
                    bg="none"
                    onClick={decreaseMonth}
                    color={textSecondary}
                    isDisabled={prevMonthButtonDisabled}
                  />
                  <Flex align="center" mx={2}>
                    <Select
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="40px"
                      maxWidth="80px"
                      borderRadius="0.75rem"
                      value={dayjs(date).year()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>

                    <Select
                      ml={1}
                      fontSize="sm"
                      size="sm"
                      color={textSecondary}
                      minWidth="100px"
                      maxWidth="200px"
                      borderRadius="0.75rem"
                      value={months[dayjs(date).month()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Flex>

                  <IconButton
                    size="xs"
                    fontSize="xs"
                    icon={<FaAngleRight />}
                    bg="none"
                    onClick={increaseMonth}
                    color={textSecondary}
                    isDisabled={nextMonthButtonDisabled}
                  />
                </Flex>
              )}
            />
          </Box>
        </HStack>
      </FormControl>
    </>
  );
};

export default FormControlDatePickerRangeDoubleEdit;
