import React from "react";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";
import Select from "react-select";
import {
  newBorder,
  white,
  primary,
  textPrimary,
  textSecondary,
} from "../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const FormControlTimePicker = ({
  label,
  value,
  placeholder,
  onChange,
  isRequired,
  isTouched,
  isError,
  errorMessage,
  w,
  isSmall,
}) => {
  // console.log("value", value);

  const { t } = useTranslation();

  // generate time options from 06:00 for all 24 hours with 15 minutes interval, use dayjs to format the time
  const generateTimeOptions = () => {
    const timeOptions = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
        const time = dayjs().utc().hour(i).minute(j).format("HH:mm");
        timeOptions.push({ value: time, label: time });
      }
    }
    return timeOptions;
  };

  const timeOptions = generateTimeOptions();

  // Custom styles for react-select
  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: isError ? "red" : state.isFocused ? newBorder : newBorder,
      borderRadius: "1rem",
      backgroundColor: white,
      border: `1px solid ${newBorder}`,
      fontSize: "0.938rem",
      fontWeight: "500",
      minHeight: "2.5rem",
      height: "3rem",
      padding: "0 0.25rem",
      ":focus-visible": {
        borderColor: primary,
        boxShadow: "none",
      },
      ":focus": {
        boxShadow: "none",
      },
    }),

    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.5 : 1,
      color: textPrimary,

      ":hover": {
        bg: "red",
      },
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? primary : white,
      color: state.isFocused ? white : textSecondary,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    }),

    groupHeading: (provided) => ({
      ...provided,
      fontSize: "11px",
      color: textSecondary,
      fontWeight: "500",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "1rem",
      border: `0px solid ${newBorder}`,
      bg: white,
      overflow: "hidden",
      padding: 0,
      margin: 0,
      zIndex: 10,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      margin: 0,
      bg: white,
    }),
  };

  // console.log("timeOptions", timeOptions);
  // console.log("time value", value);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize={isSmall ? "xs" : "sm"} position="relative">
        {label}
        {isError && isTouched && (
          <div>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </div>
        )}
      </FormLabel>

      <Select
        isSearchable
        value={timeOptions.find((option) => option.value === value)}
        onChange={onChange}
        options={timeOptions}
        placeholder={placeholder}
        styles={customStyles}
      />
    </FormControl>
  );
};

export default FormControlTimePicker;
