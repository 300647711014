import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLottie } from "lottie-react";
import successAnimation from "../../../../assets/lottie/success.json";
import { useTranslation } from "react-i18next";
import { Flex, Text, Button, Image, Stack } from "@chakra-ui/react";
import dayjs from "dayjs";
import {
  textPrimary,
  textSecondary,
  primary,
  white,
  newBorder,
} from "../../../../utils/colors";
import { shade } from "polished";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(utc);

const SuccessRegister = ({
  course,
  firstName,
  lastName,
  closeHandler,
  price,
}) => {
  const { t } = useTranslation();

  const [showAnimation, setShowAnimation] = useState(true);

  const options = {
    animationData: successAnimation,
    loop: true,
  };

  const { View } = useLottie(options);

  // const isSameDay = dayjs(course.startDate).isSame(
  //   dayjs(course.endDate),
  //   "day"
  // );

  // Use dayjs.utc to ensure dates are handled as UTC dates
  const isSameDay = dayjs
    .utc(course.startDate)
    .isSame(dayjs.utc(course.endDate), "day");

  console.log("course", course);

  return (
    <Flex direction="column" w="100%" align="center" justify="center">
      {showAnimation && (
        <Flex m="0 auto" maxW="80px">
          {View}
        </Flex>
      )}

      <Text
        fontSize="2rem"
        color={textPrimary}
        fontWeight="700"
        m="1rem auto 0.5rem"
        lineHeight="1.2"
        maxW={["100%", "100%", "500px"]}
        textAlign="center"
      >
        {t("publicCourses.successTitle")}
      </Text>
      {/* <Text
        fontSize="sm"
        color={textSecondary}
        fontWeight="500"
        maxW="400px"
        m="0 auto"
      >
        {t("publicCourses.successText")}
      </Text> */}

      <Flex
        direction="column"
        minW={["100%", "100%", "300px"]}
        maxW={["100%", "100%", "500px"]}
        w="100%"
        m="1rem 0 0"
      >
        <Flex
          w="100%"
          p="1.5rem 1.5rem"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
          direction={["column", "column", "row"]}
        >
          <Flex align="center" flexShrink={0}>
            <Image
              src={course.image}
              h={["200px", "200px", "120px"]}
              w={["100%", "100%", "120px"]}
              objectFit="cover"
              borderRadius="1rem"
              mr={[0, 0, "1rem"]}
            />
          </Flex>

          <Stack
            gap="0.25rem"
            w="100%"
            m={["1.5rem 0 0", "1.5rem 0 0", "initial"]}
          >
            <Text
              color={textPrimary}
              fontWeight="600"
              fontSize="sm"
              lineHeight="1.2"
            >
              {course.title}
            </Text>

            <Flex justify="space-between" align="center">
              <Text color={textSecondary} fontWeight="500" fontSize="13px">
                {t("publicCourses.date")}:
              </Text>
              <Text
                color={textPrimary}
                fontWeight="600"
                fontSize="13px"
                textTransform="capitalize"
              >
                {/* {isSameDay
                  ? dayjs(course.startDate).format("D MMMM, YYYY")
                  : `${dayjs(course.startDate).format("D MMM")} - ${dayjs(
                      course.endDate
                    ).format("D MMM, YYYY")}`} */}

                {isSameDay
                  ? dayjs.utc(course.startDate).format("D MMMM, YYYY")
                  : `${dayjs.utc(course.startDate).format("D MMM")} - ${dayjs
                      .utc(course.endDate)
                      .format("D MMM, YYYY")}`}
              </Text>
            </Flex>

            <Flex justify="space-between" align="center">
              <Text color={textSecondary} fontWeight="500" fontSize="13px">
                {t("publicCourses.startTime")}:
              </Text>
              <Text color={textPrimary} fontWeight="500" fontSize="13px">
                {/* {dayjs(course.startDate).format("HH:mm")} */}
                {dayjs.utc(course.startDate).format("HH:mm")}
              </Text>
            </Flex>

            <Flex justify="space-between" align="center">
              <Text color={textSecondary} fontWeight="500" fontSize="13px">
                {t("attendee.attendee")}:
              </Text>
              <Text color={textPrimary} fontWeight="500" fontSize="13px">
                {firstName && firstName} {lastName && lastName}
              </Text>
            </Flex>

            <Flex w="100%">
              <Flex justify="space-between" align="center" w="100%">
                <Text color={textSecondary} fontWeight="500" fontSize="13px">
                  {t("common.priceAttendee")}:
                </Text>

                {price === 0 ? (
                  <Text color={textPrimary} fontWeight="500" fontSize="13px">
                    {t("common.free")}
                  </Text>
                ) : (
                  <Text color={textPrimary} fontWeight="500" fontSize="13px">
                    {price} €
                  </Text>
                )}
              </Flex>
            </Flex>
          </Stack>
        </Flex>

        <Flex justify="center" align="center" m="3rem 0 4rem" w="100%">
          <Button
            bg={primary}
            color={white}
            size="lg"
            fontSize="sm"
            borderRadius="10rem"
            px="3rem"
            _hover={{ bg: shade(0.3, primary) }}
            onClick={closeHandler}
          >
            {t("publicCourses.backToEvents")}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SuccessRegister;
