import React, { useState, useEffect } from "react";
import {
  Flex,
  Text,
  Tooltip,
  Icon,
  Box,
  Heading,
  IconButton,
  Avatar,
  HStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";
import { TbUsers, TbWallet } from "react-icons/tb";
import {
  textPrimary,
  textSecondary,
  white,
  backgroundLight,
  newBorder,
} from "../../../../../utils/colors";
import { icons } from "../../../../../utils/icons";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import TooltipText from "../../../../../components/partials/TooltipText";
import { shade } from "polished";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

dayjs.extend(utc);
dayjs.extend(timezone);

const CourseInfo = ({ course }) => {
  const { t } = useTranslation();

  // STATE
  const [place, setPlace] = useState("");
  const [city, setCity] = useState("");
  const [location, setLocation] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0); // Add state for current slide
  const [courseDate, setCourseDate] = useState("");
  const [courseStartTime, setCourseStartTime] = useState("");

  // USE EFFECT
  useEffect(() => {
    if (course?.address && course?.address.length > 0) {
      setPlace(
        course?.address[0]?.street + `, ` + course?.address[0]?.zip || ""
      );
      setCity(course?.address[0]?.city + `, ` + course?.address[0]?.country);
    }
    setLocation(course.location);
  }, [course]);

  // CUSTOM
  // Define the titles for different slides
  const slideTitles = [t("course.courseDetails"), t("course.coursePricing")];

  const MiniSliderArrowBackIcon = () => (
    <img src={icons.miniSliderArrowBack} alt="<" />
  );
  const MiniSliderArrowForwardIcon = () => (
    <img src={icons.miniSliderArrowForward} alt=">" />
  );

  // HANDLERS

  // Define a function to handle slide change
  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex); // Use realIndex to get the correct slide index
  };

  // console.log("course prices", course.prices);

  // Assuming `course.timezoneOffset` and `course.timezone` are available from the backend
  const courseTimezoneDisplay = `(GMT ${course.timezoneOffset})`;

  // console.log("course", course);
  // console.log("courseTimezoneDisplay", courseTimezoneDisplay);

  // const courseDate = course.date
  //   ? dayjs(course.date).tz(course.timezone).format("DD.MM.YYYY HH:mm") +
  //     " " +
  //     courseTimezoneDisplay
  //   : "";

  useEffect(() => {
    if (course.startDate && course.endDate) {
      console.log("course.startDate", course.startDate);
      console.log("course.endDate", course.endDate);

      const startDate = dayjs(course.startDate).utc();
      const endDate = dayjs(course.endDate).utc();

      if (startDate.isSame(endDate, "day")) {
        // If start and end are on the same day
        setCourseDate(`${startDate.format("HH:mm MMMM D, YYYY")}`);
      } else {
        // If start and end are on different days
        setCourseDate(
          `${startDate.format("HH:mm MMMM D")} - ${endDate.format(
            "MMMM D, YYYY"
          )}`
        );
      }

      setCourseStartTime(startDate.format("HH:mm"));
    }
  }, [course]);

  console.log("course", course);

  console.log("courseDate", courseDate);
  console.log("courseStartTime", courseStartTime);

  const infoArray = [
    {
      label: "Course Date",
      value: (
        <TooltipText
          icon="TbCalendarEvent"
          label={t("form.courseDate")}
          value={courseDate || "-"}
        />
      ),
    },
    {
      label: "Location",
      value: (
        <TooltipText
          icon="TbMapPinStar"
          label={t("course.location")}
          value={location || "-"}
        />
      ),
    },

    {
      label: "Place",
      value: (
        <TooltipText
          icon="TbMapPin"
          label={t("form.address")}
          value={place || "-"}
        />
      ),
    },

    {
      label: "City",
      value: (
        <TooltipText
          icon="TbBuilding"
          label={t("form.city")}
          value={city || "-"}
        />
      ),
    },

    {
      label: "Speaker2",
      value: (
        <Tooltip
          p="0.5rem"
          borderRadius="1rem"
          label={
            <Flex alignItems="center">
              <Icon as={TbUsers} mt="-2px" mr="0.5rem" color={white} />
              <Text fontSize="sm" color={shade(0.3, white)} fontWeight="500">
                {t("course.speakers")}:
              </Text>
              {course.speakers && course.speakers.length > 0 ? (
                <Flex direction="column">
                  {course.speakers.map((speaker, index) => (
                    <Flex key={index} alignItems="center">
                      <Text
                        fontSize="sm"
                        color={white}
                        fontWeight="500"
                        ml={2}
                        textTransform="capitalize"
                      >
                        {speaker && speaker.firstName && speaker.firstName[0]}.{" "}
                        {speaker && speaker.lastName && speaker.lastName}
                      </Text>
                    </Flex>
                  ))}
                </Flex>
              ) : (
                <Text fontSize="1rem" color={white} fontWeight="500">
                  -
                </Text>
              )}
            </Flex>
          }
          placement="top"
        >
          <Flex alignItems="center">
            <Icon as={TbUsers} mr="0.5rem" color={textSecondary} />
            {course.speakers && course.speakers.length > 0 ? (
              course.speakers.length === 1 ? (
                <>
                  <Avatar
                    size="xs"
                    src={(course && course.speakers[0].image) || ""}
                    name={course && course.speakers[0].firstName}
                    border={`1px solid ${newBorder}`}
                  />
                  <Text
                    fontSize="sm"
                    color={textPrimary}
                    fontWeight="500"
                    ml={1}
                  >
                    {course &&
                      course.speakers[0].firstName +
                        " " +
                        course.speakers[0].lastName}
                  </Text>
                </>
              ) : (
                course &&
                course.speakers.map((speaker, index) => (
                  <Tooltip
                    label={speaker && speaker.firstName && speaker.firstName}
                    key={index}
                  >
                    <Avatar
                      size="xs"
                      src={(speaker && speaker.image && speaker.image) || ""}
                      name={speaker && speaker.firstName && speaker.firstName}
                      border={`1px solid ${newBorder}`}
                      ml={index === 0 ? 0 : "-0.75rem"} // Adjust margin for the first avatar
                    />
                  </Tooltip>
                ))
              )
            ) : (
              <Text fontSize="1rem" color={textPrimary} fontWeight="500">
                -
              </Text>
            )}
          </Flex>
        </Tooltip>
      ),
    },
  ];

  const priceArray = [
    // {
    //   label: "Price2",
    //   value: (
    //     <TooltipText
    //       icon="TbWallet"
    //       label={t("form.city")}
    //       value={price.price || "-"}
    //     />
    //   ),
    // },

    {
      label: "Price",
      value: (
        <Flex alignItems="center" w="100%">
          <Box w="100%">
            {course.prices && course.prices.length > 0
              ? course.prices.map((price, index) => (
                  <Flex
                    key={index}
                    align="center"
                    justify="space-between"
                    p="0.35rem 0"
                    w="100%"
                  >
                    <HStack gap={0}>
                      <Icon as={TbWallet} mr="0.5rem" color={textSecondary} />
                      <Text fontSize="sm" color={textPrimary} fontWeight="500">
                        {price &&
                          price.professionGroup &&
                          price.professionGroup.name &&
                          price.professionGroup.name}
                        :
                      </Text>
                    </HStack>
                    <Text
                      ml={2}
                      fontSize="sm"
                      color={textSecondary}
                      fontWeight="500"
                    >
                      &euro; {price.price}
                    </Text>
                  </Flex>
                ))
              : "-"}
          </Box>
        </Flex>
      ),
    },
  ];

  // console.log("address", course.address);

  // console.log("course", course);

  return (
    <Box w="45%" pr={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Heading fontSize="md" fontWeight="600" color={textPrimary}>
          {slideTitles[currentSlide]}
        </Heading>
        <Box>
          <IconButton
            className="custom-swiper-button custom-swiper-button-prev"
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowBackIcon />}
            borderRadius="10.5rem"
            opacity="0.8"
            _hover={{
              background: backgroundLight,
            }}
          />
          <IconButton
            className="custom-swiper-button custom-swiper-button-next"
            h="2.5rem"
            w="1.5rem"
            bg={white}
            border={`1px solid ${newBorder}`}
            icon={<MiniSliderArrowForwardIcon />}
            borderRadius="10.5rem"
            ml="0.5rem"
            opacity="0.5"
            _hover={{
              background: backgroundLight,
            }}
          />
        </Box>
      </Flex>
      <Box>
        <Swiper
          navigation={{
            prevEl: ".custom-swiper-button-prev", // Selector for your custom previous button
            nextEl: ".custom-swiper-button-next", // Selector for your custom next button
          }}
          slidesPerView={1}
          pagination={{
            dynamicBullets: true,
          }}
          modules={[Pagination, Navigation]}
          onSlideChange={handleSlideChange}
          className="courseInfoSlider"
        >
          <SwiperSlide>
            <Box>
              {infoArray.map((item, index) => (
                <Flex key={index} alignItems="center">
                  {item.value && (
                    <Flex p="0.35rem 0" alignItems="center">
                      {item.value}
                    </Flex>
                  )}
                </Flex>
              ))}
            </Box>
          </SwiperSlide>
          <SwiperSlide>
            {!course.free ? (
              <Box>
                {priceArray.map((item, index) => (
                  <Flex key={index} alignItems="center" w="100%">
                    {item.value && <Flex w="100%">{item.value}</Flex>}
                  </Flex>
                ))}
              </Box>
            ) : (
              <Box>
                <Flex alignItems="center" w="100%">
                  <Box w="100%">
                    <Flex
                      align="center"
                      justify="space-between"
                      p="0.35rem 0"
                      w="100%"
                    >
                      <HStack gap={0}>
                        <Icon as={TbWallet} mr="0.5rem" color={textSecondary} />
                        <Text
                          fontSize="sm"
                          color={textPrimary}
                          fontWeight="500"
                        >
                          {t("course.courseFreeText")}
                        </Text>
                      </HStack>
                      <Text
                        ml={2}
                        fontSize="sm"
                        color={textSecondary}
                        fontWeight="500"
                      >
                        &euro; 0
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            )}
          </SwiperSlide>
        </Swiper>
      </Box>
    </Box>
  );
};

export default CourseInfo;
