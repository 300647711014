import React, { useEffect } from "react";
import { Flex, Text, Image, Stack } from "@chakra-ui/react";
import {
  textPrimary,
  newBorder,
  textSecondary,
} from "../../../../utils/colors";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
// import { useMediaQuery } from "react-responsive";
import moment from "moment";

dayjs.extend(utc);

const EventSummary = ({
  course,

  price,
}) => {
  const { t } = useTranslation();

  const [adjustedStartDate, setAdjustedStartDate] = React.useState(null);
  const [adjustedEndDate, setAdjustedEndDate] = React.useState(null);

  useEffect(() => {
    const userOffsetMinutes = new Date().getTimezoneOffset();

    if (course.startDate) {
      const adjustedStartDate = moment(course.startDate)
        .add(userOffsetMinutes, "minutes")
        .toDate();
      setAdjustedStartDate(adjustedStartDate);
    }

    if (course.endDate) {
      const adjustedEndDate = moment(course.endDate)
        .add(userOffsetMinutes, "minutes")
        .toDate();
      setAdjustedEndDate(adjustedEndDate);
    }
  }, [course]);

  // const isSameDay = dayjs(course.startDate).isSame(course.endDate, "day");
  const isSameDay = dayjs(adjustedStartDate).isSame(
    dayjs(adjustedEndDate),
    "day"
  );

  const getLowestPrice = () => {
    // Ensure the prices array exists and has at least one price object
    if (course.prices && course.prices.length > 0) {
      // Clone to avoid mutating the original array, then sort by price
      const sortedPrices = [...course.prices].sort((a, b) => a.price - b.price);
      // Return the lowest price
      return sortedPrices[0].price;
    }
    return null; // Return null or a default value if no prices are available
  };

  const lowestPrice = getLowestPrice(); // This will hold the lowest price

  //LOGS
  // console.log("course", course);
  // console.log("adjustedStartDate", adjustedStartDate);
  // console.log("adjustedEndDate", adjustedEndDate);
  // console.log("isSameDay", isSameDay);

  // RENDER
  return (
    <div>
      <Flex
        direction="column"
        minW={["100%", "100%", "300px"]}
        maxW={["100%", "100%", "300px"]}
        w="100%"
        m="0rem 0 0"
      >
        <Flex
          direction="column"
          w="full"
          p="1.5rem 1.5rem"
          border={`1px solid ${newBorder}`}
          borderRadius="1rem"
        >
          <Flex align="center">
            <Image
              src={course.image}
              w="80px"
              h="80px"
              objectFit="cover"
              borderRadius="1rem"
              mr="1rem"
              flexShrink="0"
            />
            <Text
              color={textPrimary}
              fontWeight="600"
              fontSize="sm"
              lineHeight="1.2"
            >
              {course.title}
            </Text>
          </Flex>

          <Stack gap="0.5rem" m="1.5rem 0 0" p="0 0 1.5rem">
            <Flex justify="space-between" align="center">
              <Text color={textSecondary} fontWeight="500" fontSize="13px">
                {t("publicCourses.date")}:
              </Text>
              <Text
                color={textPrimary}
                fontWeight="600"
                fontSize="13px"
                textTransform="capitalize"
              >
                {isSameDay
                  ? dayjs(adjustedStartDate).utc().format("D MMMM, YYYY")
                  : `${dayjs(adjustedStartDate).format("MMM D")} - ${dayjs(
                      adjustedEndDate
                    ).format("D MMM, YYYY")}`}
              </Text>
            </Flex>
            <Flex justify="space-between" align="center">
              <Text color={textSecondary} fontWeight="500" fontSize="13px">
                {t("publicCourses.startTime")}:
              </Text>
              <Text color={textPrimary} fontWeight="500" fontSize="13px">
                {dayjs(course.startDate).utc().format("HH:mm")}
              </Text>
            </Flex>
            <Flex justify="space-between" align="center">
              <Text color={textSecondary} fontWeight="500" fontSize="13px">
                {t("publicCourses.address")}:
              </Text>
              <Text
                color={textPrimary}
                fontWeight="500"
                fontSize="13px"
                maxW="120px"
                isTruncated
              >
                {course.address.length > 0
                  ? course.address[0].street + ", " + course.address[0].city
                  : "-"}
              </Text>
            </Flex>

            {(!course.prices || course.prices.length === 0) && (
              <Flex justify="space-between" align="center">
                <Text color={textSecondary} fontWeight="500" fontSize="13px">
                  {t("common.price")}:
                </Text>
                <Text color={textPrimary} fontWeight="500" fontSize="13px">
                  {/* {lowestPrice
                            ? `${lowestPrice} €
          
                            ` // Add the euro symbol here`
                            : t("publicCourses.notAvailable")} */}
                  {t("common.free")}
                </Text>
              </Flex>
            )}

            {(!course.prices || course.prices.length > 0) && (
              <Flex w="100%">
                {price > 0 ? (
                  <>
                    <Flex justify="space-between" align="center" w="100%">
                      <Text
                        color={textSecondary}
                        fontWeight="500"
                        fontSize="13px"
                      >
                        {t("common.priceAttendee")}:
                      </Text>

                      <Text
                        color={textPrimary}
                        fontWeight="500"
                        fontSize="13px"
                      >
                        {price} €
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex justify="space-between" align="center" w="100%">
                      <Text
                        color={textSecondary}
                        fontWeight="500"
                        fontSize="13px"
                      >
                        {t("common.priceFrom")}:
                      </Text>

                      <Text
                        color={textPrimary}
                        fontWeight="500"
                        fontSize="13px"
                      >
                        {lowestPrice} €
                      </Text>
                    </Flex>
                  </>
                )}
              </Flex>
            )}
          </Stack>

          {/* <Flex justify="space-between" align="center" m="1rem 0 0" w="100%">
            <Tooltip
              bg={buttonRed}
              color={buttonRedText}
              p="0.5rem 1rem"
              borderRadius="1rem"
              label={
                <Flex direction="column" align="center">
                  {!isFirstNameValid && (
                    <Text fontSize="xs">
                      {t("formError.firstNameRequired")}
                    </Text>
                  )}
                  {!isLastNameValid && (
                    <Text fontSize="xs">{t("formError.lastNameRequired")}</Text>
                  )}
                  {!isEmailValid && (
                    <Text fontSize="xs">{t("formError.emailRequired")}</Text>
                  )}
                  {!isProfessionValid && (
                    <Text fontSize="xs">
                      {t("formError.professionRequired")}
                    </Text>
                  )}
                  {isBusiness && !isCompanyNameValid && (
                    <Text fontSize="xs">
                      {t("formError.companyNameRequired")}
                    </Text>
                  )}
                  {isBusiness && !isCompanyCodeValid && (
                    <Text fontSize="xs">
                      {t("formError.companyCodeRequired")}
                    </Text>
                  )}
                </Flex>
              }
              placement="top"
              isDisabled={!isDisabledButton}
            >
              <Button
                bg={primary}
                color={white}
                size="lg"
                fontSize="sm"
                borderRadius="10rem"
                w="100%"
                isDisabled={isDisabledButton}
                onClick={handleRegister}
                _hover={{ bg: shade(0.3, primary) }}
              >
                <Flex
                  align="center"
                  justify="center"
                  bg={white}
                  borderRadius="50%"
                  p="0.5rem"
                  mr="1rem"
                >
                  <Icon as={TbCalendarPlus} color={primary} fontSize="1rem" />
                </Flex>
                {t("publicCourses.register")}
              </Button>
            </Tooltip>
          </Flex> */}
        </Flex>
        {/* <Flex
          w="100%"
          align="center"
          justify="center"
          direction="column"
          p="1.5rem 1.5rem"
        >
          <Flex align="center">
            <Icon as={FaCcStripe} color={secondary} fontSize="1.5rem" mr={2} />
            <Text color={textPrimary} fontWeight="500" fontSize="sm">
              {t("module.securePayment")}
            </Text>
          </Flex>
          <Text
            color={textSecondary}
            fontSize="xs"
            fontWeight="500"
            m="0.5rem 0 0"
            textAlign="center"
          >
            {t("module.securePaymentText")}
          </Text>
        </Flex> */}
      </Flex>
    </div>
  );
};

export default EventSummary;
