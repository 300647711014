import React, { useState, useEffect, useContext } from "react";
import { GoogleApiContext } from "../../contexts/GoogleApiContext";
import usePlacesAutocomplete, { getGeocode } from "use-places-autocomplete";
import {
  FormControl,
  FormLabel,
  Box,
  FormErrorMessage,
  Input,
  Flex,
  Text,
} from "@chakra-ui/react";
import {
  primary,
  white,
  newBorder,
  backgroundLight,
  textPrimary,
  textSecondary,
} from "../../utils/colors";
import { t } from "i18next";

const FormControlAddress = ({
  currentValue,
  label,
  onAddressSelect,
  isRequired,
  isTouched,
  setIsTouched,
  isError,
  errorMessage,
  w,
}) => {
  const { isApiLoaded } = useContext(GoogleApiContext);

  const [isSelected, setIsSelected] = useState(false);

  const {
    ready,

    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const [inputValue, setInputValue] = useState(
    currentValue ? currentValue : ""
  );
  const [isSearching, setIsSearching] = useState(true);

  // State variable to track whether the input field is focused
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    if (isApiLoaded && isSearching) {
      setValue(inputValue);
    }
  }, [isApiLoaded, inputValue, isSearching, setValue]);

  // Use useEffect to set inputValue from currentValue when the component mounts
  useEffect(() => {
    if (currentValue !== inputValue) {
      setInputValue(currentValue);
    }
  }, [currentValue]);

  const handleSelect = (address) => {
    setIsTouched && setIsTouched(true); // Set isTouched to true

    const formattedValue = address.split(",").slice(0, 1).join(",");
    setInputValue(formattedValue);
    setIsSelected(true);

    // setValue(formattedValue, false); // Prevent suggestions from appearing

    // Clear suggestions with a slight delay to ensure inputValue persists
    // setTimeout(() => {
    //   clearSuggestions();
    // }, 100); // Delay to help retain input value briefly

    getGeocode({ address })
      .then((results) => {
        const addressComponents = results[0].address_components;

        let localCity = "";
        let localZip = "";
        let localStreet = "";
        let localCountry = "";
        let localState = "";
        let localHouseNumber = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            localCity = component.long_name;
          } else if (component.types.includes("postal_code")) {
            localZip = component.long_name;
          } else if (component.types.includes("route")) {
            localStreet = component.long_name;
          } else if (component.types.includes("country")) {
            localCountry = component.short_name;
          } else if (component.types.includes("administrative_area_level_1")) {
            localState = component.long_name;
          } else if (component.types.includes("street_number")) {
            localHouseNumber = component.long_name;
          }
        });

        console.log("results", results);

        // Call the onAddressSelect callback function provided by the parent component
        onAddressSelect({
          street: localStreet + " " + localHouseNumber,
          city: localCity,
          zip: localZip,
          state: localState,
          country: localCountry,
        });
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    setIsSearching(false);
    clearSuggestions();
  };

  const handleInput = (e) => {
    setIsSelected(false);

    const newInputValue = e.target.value;
    setInputValue(newInputValue); // Update state with the new input value

    setIsTouched && setIsTouched(true);

    if (!isSearching) {
      setIsSearching(true); // Allow search when user starts typing
    }

    // If the input field is cleared, update the street, city, zip, state, and country values
    if (newInputValue === "") {
      onAddressSelect({
        street: "",
        city: "",
        zip: "",
        state: "",
        country: "",
      });
    }
  };

  // Event handler to set isInputFocused to true when input is focused
  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      if (!isInputFocused) {
        return null;
      }

      return (
        <Flex
          key={place_id}
          onClick={() => handleSelect(suggestion.description)}
          align="center"
          p="0.5rem 1rem"
          borderBottom={`1px solid ${newBorder}`}
          _last={{ borderBottom: "none" }}
          _hover={{ bg: backgroundLight, cursor: "pointer" }}
        >
          <Text fontSize="sm" color={textPrimary}>
            {main_text}{" "}
            <Text as="span" fontSize="sm" color={textSecondary}>
              {secondary_text}
            </Text>
          </Text>
        </Flex>
      );
    });

  // console.log("value", inputValue);

  return (
    <FormControl
      mb={4}
      isRequired={isRequired}
      isInvalid={isTouched && isError}
      w={w}
    >
      <FormLabel fontSize="sm" position="relative" color={textPrimary}>
        {label}
        {isError && isTouched && (
          <Box position="absolute" top={-1} right={0} zIndex={2}>
            <FormErrorMessage fontSize="xs">{errorMessage}</FormErrorMessage>
          </Box>
        )}
      </FormLabel>
      <Input
        bg={white}
        w="100%"
        type="text"
        value={inputValue}
        onFocus={handleInputFocus}
        onChange={handleInput}
        disabled={!ready || !isApiLoaded}
        placeholder={t("placeholder.address")}
        color={textPrimary}
        borderRadius="0.75rem"
        size="lg"
        fontSize="0.938rem"
        fontWeight="500"
        border={`1px solid ${newBorder}`}
        boxShadow="none !important"
        // className="fontGlobal"
      />
      {status === "OK" && (
        <Flex
          direction="column"
          w="100%"
          position="absolute"
          zIndex={2}
          bg={white}
          borderRadius="0.75rem"
          mt={2}
        >
          {renderSuggestions()}
        </Flex>
      )}
    </FormControl>
  );
};

export default FormControlAddress;
