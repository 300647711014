import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Button,
  useToast,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  white,
  primary,
  textSecondary,
  backgroundLight,
} from "../../../../../utils/colors";
import {
  listCourseDetails,
  updateCourseDescription,
} from "../../../../../actions/courseActions";
import { Editor } from "@tinymce/tinymce-react";
import ToastComponent from "../../../../../components/partials/ToastComponent";
import { COURSE_DESCRIPTION_UPDATE_RESET } from "../../../../../constants/courseConstants";

const TabCourseDescription = ({ title }) => {
  const courseId = useParams().id;
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  // STATE

  const [editDescriptionSidePanelOpen, setEditDescriptionSidePanelOpen] =
    useState(false);
  const [description, setDescription] = useState(""); // State variable to store the description

  const [isEdited, setIsEdited] = useState(false);

  // REDUX
  const dispatch = useDispatch();

  const courseDetails = useSelector((state) => state.courseDetails);
  const { course } = courseDetails;

  const courseDescriptionUpdate = useSelector(
    (state) => state.courseDescriptionUpdate
  );
  const {
    success: successUpdateDescription,
    error: errorUpdateDescription,
    loading: loadingUpdateDescription,
  } = courseDescriptionUpdate;

  // HANDLERS
  const handleEditorChange = (content) => {
    setDescription(content);
    setIsEdited(content !== course.description); // Compare with the initial value
  };

  const openEditDescriptionSidePanel = () => {
    setEditDescriptionSidePanelOpen(true);
  };

  const closeEditDescriptionSidePanel = () => {
    setEditDescriptionSidePanelOpen(false);
  };

  const updateDescriptionHandler = () => {
    dispatch(updateCourseDescription(courseId, description));
    closeEditDescriptionSidePanel();
  };

  useEffect(() => {
    if (!course || course._id !== courseId) {
      dispatch(listCourseDetails(courseId));
    } else {
      setDescription(course.description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, course, courseId]);

  // UseEffect for handling toasts separately
  useEffect(() => {
    if (successUpdateDescription) {
      toast({
        title: t("toasts.courseDescriptionUpdated"),
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsEdited(false); // Reset after successful update
      dispatch({ type: COURSE_DESCRIPTION_UPDATE_RESET });
    } else if (errorUpdateDescription) {
      const isPayloadTooLarge = errorUpdateDescription.includes(
        "request entity too large"
      );

      toast({
        title: isPayloadTooLarge
          ? t("toasts.courseDescriptionImageSizeError")
          : t("toasts.courseDescriptionError"),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      dispatch({ type: COURSE_DESCRIPTION_UPDATE_RESET });
    }
  }, [successUpdateDescription, errorUpdateDescription, toast, t, dispatch]);

  useEffect(() => {
    if (successUpdateDescription) {
      setIsEdited(false); // Reset after update
      // ...rest of your code
    }
  }, [successUpdateDescription, dispatch, course, courseId]);

  return (
    <>
      <Box p="0rem 0rem">
        <Flex align="center" justify="space-between" p="2rem 0">
          <Stack gap={1}>
            <Heading fontSize="xl" fontWeight="600">
              {t("course.courseDescription")}
            </Heading>
            <Text color={textSecondary} fontSize="sm">
              {title}
            </Text>
          </Stack>

          <Button
            minWidth="10rem"
            type="submit"
            size="lg"
            bg={primary}
            color={white}
            fontSize="md"
            borderRadius="10rem"
            onClick={updateDescriptionHandler}
            isDisabled={!isEdited}
          >
            {t("button.update")}
          </Button>
        </Flex>

        <Flex
          p="1.5rem 1.5rem"
          bg={backgroundLight}
          // border={`1px dashed ${newBorder}`}
          borderRadius="1rem"
          className="courseDescription"
        >
          <Editor
            apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
            value={description}
            onEditorChange={handleEditorChange}
            init={{
              width: "100%",
              height: 600,
              menubar: true,
              plugins: "image code",
              automatic_uploads: true,
              file_picker_types: "image",
              file_picker_callback: (cb, value, meta) => {
                const input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", "image/*");

                input.onchange = function () {
                  const file = this.files[0];

                  // Simple image upload handling
                  const reader = new FileReader();
                  reader.onload = () => {
                    const base64 = reader.result.split(",")[1];
                    cb(`data:image/jpeg;base64,${base64}`, {
                      title: file.name,
                    });
                  };
                  reader.readAsDataURL(file);
                };

                input.click();
              },
              content_style: `
      body { 
        font-family: 'Gilroy', sans-serif; 
        font-size: 16px; 
        line-height: 1.6; 
        color: #150A40;
        width: 100%;
      }
      p {
        margin: 0;
      }
        .mce-content-body {
        max-width: 100%;
        overflow-x: hidden;
            margin: 0;
            padding: 1.5rem;
        }
    `,
            }}
          />
        </Flex>
      </Box>
    </>
  );
};

export default TabCourseDescription;
