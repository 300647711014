import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Flex,
  Box,
  Button,
  FormControl,
  FormLabel,
  useToast,
  HStack,
  Input,
  TabPanels,
  TabPanel,
  Text,
  Stack,
  Icon,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  InputGroup,
  InputLeftAddon,
  Circle,
  Tooltip,
} from "@chakra-ui/react";
import {
  newBorder,
  backgroundLight,
  textSecondary,
  white,
  primary,
  textPrimary,
  backgroundSky,
} from "../../../../utils/colors";
import {
  updateCourse,
  listCourseDetails,
  addCourseSpeaker,
  removeCourseSpeaker,
} from "../../../../actions/courseActions";
import MiniNoItems from "../../../../components/partials/MiniNoItems";
import { TbPlus } from "react-icons/tb";
import ImageUpload from "../../../../components/input/ImageUpload";
import { useTranslation } from "react-i18next";
import TabsComponent from "../../../../components/tabs/TabsComponent";
import MultiSpeakerSelectNoInactiveNoCurrent from "../../../../components/select/MultiSpeakerSelectNoInactiveNoCurrent";
import SpeakerList from "./partials/SpeakerList";
import { Editor } from "@tinymce/tinymce-react";
import ToastComponent from "../../../../components/partials/ToastComponent";
import FormControlDefault from "../../../../components/input/FormControlDefault";
import FormControlAddress from "../../../../components/input/FormControlAddress";
import FormControlCountry from "../../../../components/input/FormControlCountry";
import FormControlTimePicker from "../../../../components/input/FormControlTimePicker";
import { shade } from "polished";
import moment from "moment-timezone";
import FormControlTimezone from "../../../../components/input/FormControlTimezone";
import FormControlDatePickerRangeDoubleEdit from "../../../../components/input/FormControlDatePickerRangeDoubleEdit";

const EditCourse = ({ courseId }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const toastComponent = ToastComponent();
  const { t } = useTranslation();

  const tabsData = [
    { label: t("course.details") },
    { label: t("course.dateTimeLocation") },
    // { label: t('course.speakers') },
    // { label: t('course.tabDescription') },
    // { label: t('course.tabPricing') },
  ];

  const [showAddSpeaker, setShowAddSpeaker] = useState(false);

  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [maximumAttendees, setMaximumAttendees] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [speakers, setSpeakers] = useState([]);
  const [speakerId, setSpeakerId] = useState("");
  const [newlyAddedSpeakers] = useState([]);
  const [customSpeakers, setCustomSpeakers] = useState([]);
  const [updatedSpeakers, setUpdatedSpeakers] = useState([]);
  const [showCreateSpeaker] = useState(false);
  const [prices, setPrices] = useState([]);
  const [free, setFree] = useState(true);

  const [timezone, setTimezone] = useState("");
  const [timezoneOffset, setTimezoneOffset] = useState("");

  const [attendees, setAttendees] = useState([]);

  const courseDetails = useSelector((state) => state.courseDetails);
  const { course } = courseDetails;

  const courseSpeakerAdd = useSelector((state) => state.courseSpeakerAdd);
  const { success: successSpeakerAdd } = courseSpeakerAdd;

  const handleShowAddSpeaker = () => setShowAddSpeaker(!showAddSpeaker);
  const [createdSpeakerId] = useState(null);

  // TOUCHED
  const [titleTouched, setTitleTouched] = useState(false);
  const [startDateTouched, setStartDateTouched] = useState(false);
  const [endDateTouched, setEndDateTouched] = useState(false);

  // ERRORS
  const isTitleError = title === "";
  const isStartDateError = startDate === "";
  const isEndDateError = endDate === "";
  const isTimezoneError = timezone === "";

  // VALIDATION
  const isTitleValid = title.length >= 1;
  const isStartDateValid = startDate !== null;
  const isEndDateValid = endDate !== null;
  const isTimezoneValid = timezone !== "";

  // Determine if the Continue button should be disabled
  const isContinueDisabled =
    !isTitleValid || !isStartDateValid || !isEndDateValid || !isTimezoneValid;

  const isDateError = !isStartDateValid || !isEndDateValid;

  // console.log("isDateError", isDateError);

  const updatePrice = (professionGroupId, newPrice) => {
    // Create a copy of the prices array
    const updatedPrices = [...prices];

    // Find the index of the profession group in the updatedPrices array
    const index = updatedPrices.findIndex(
      (price) => price.professionGroup._id === professionGroupId
    );

    if (index !== -1) {
      // Update the price for the specific profession group
      updatedPrices[index].price = newPrice;
      // Update the state with the new prices
      setPrices(updatedPrices);
    }
  };

  const handleAddressSelect = ({ street, city, zip, country, state }) => {
    setStreet(street);
    setCity(city);
    setZip(zip);
    setCountry(country);
    setState(state);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    // Combine `startDate` and `startTime` in UTC
    let updatedStartDate = moment.tz(startDate, timezone).utc();
    if (startTime) {
      const [hours, minutes] = startTime.split(":").map(Number);
      updatedStartDate = updatedStartDate
        .set("hour", hours)
        .set("minute", minutes);
    }

    // Similarly, adjust `endDate` to UTC at 23:59:59 or a specific time
    let updatedEndDate = moment.tz(endDate, timezone).utc();
    updatedEndDate = updatedEndDate
      .set("hour", 23)
      .set("minute", 59)
      .set("second", 59);

    const updatedCourse = {
      _id: courseId,
      title: title,
      image: image,
      location: location,
      startDate: updatedStartDate,
      endDate: updatedEndDate,
      city: city,
      street: street,
      zip: zip,
      country: country,
      state: state,
      maximumAttendees: maximumAttendees,
      attendees: attendees,
      speakers: updatedSpeakers,
      description: description,
      prices: prices,
      free: free,
      timezone,
      timezoneOffset,
    };

    console.log("updatedCourse", updatedCourse);

    dispatch(updateCourse(updatedCourse));
  };

  const removeSpeakerHandler = (speakerId) => {
    dispatch(removeCourseSpeaker(courseId, speakerId));

    setUpdatedSpeakers(updatedSpeakers.filter((s) => s !== speakerId));
    setSpeakers(speakers.filter((s) => s._id !== speakerId));

    toastComponent.showToast(t("toasts.speakerRemove"), "success", 3000);
  };

  const addSpeakerHandleNew = (values) => {
    // Get the array of speaker IDs from the values array
    const speakerIds = values.map((speaker) => speaker.value);
    const speakerExists = speakerIds.some((speakerId) =>
      speakers.some((a) => a._id === speakerId)
    );

    if (speakerExists) {
      console.log("SPEAKER EXISTS");
      toastComponent.showToast(t("toasts.speakerError"), "error", 3000);
    } else {
      console.log("speakerIds", speakerIds);
      setUpdatedSpeakers([...updatedSpeakers, ...speakerIds]);

      console.log("after updatedSpeakers", updatedSpeakers);
      dispatch(addCourseSpeaker(courseId, speakerIds));
      toastComponent.showToast(t("toasts.speakerAdd"), "success", 3000);
    }
  };

  // Update the handler to work with date range
  const handleDateRangeChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    if (!course._id || course._id !== courseId) {
      dispatch(listCourseDetails(courseId));
    } else {
      setTitle(course.title);
      setImage(course.image);
      setLocation(course.location);

      setStartDate(new Date(course.startDate));
      setEndDate(new Date(course.endDate));

      // Extract only the hour and minute from startDate
      const startTimeValue = new Date(course.startDate)
        .toISOString()
        .slice(11, 16);

      setStartTime(startTimeValue);
      setCity(course?.address[0]?.city || "");
      setStreet(course.address[0]?.street || "");
      setZip(course.address[0]?.zip || "");
      setCountry(course.address[0]?.country || "");
      setState(course.address[0]?.state || "");
      setMaximumAttendees(course.maximumAttendees);
      setSpeakers(course.speakers);
      setUpdatedSpeakers(course.speakers.map((speaker) => speaker._id));
      setDescription(course.description);
      setPrices(course.prices);
      setFree(course.free);
      setAttendees(course.attendees);
      setTimezone(course.timezone);
      setTimezoneOffset(moment.tz(course.timezone).format("Z"));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [dispatch, courseId, course]);

  useEffect(() => {
    if (createdSpeakerId) {
      console.log("createdSpeakerId", createdSpeakerId);
      setSpeakers([...speakers, createdSpeakerId._id]);
      setCustomSpeakers([
        ...customSpeakers,
        {
          label: createdSpeakerId.name,
          value: createdSpeakerId._id,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdSpeakerId]);

  useEffect(() => {
    if (successSpeakerAdd) {
      dispatch(listCourseDetails(courseId));
      setSpeakerId("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSpeakerAdd, courseId, dispatch]);

  useEffect(() => {
    if (course._id === courseId) {
      setSpeakers(course.speakers);
    }
  }, [course, courseId]);

  console.log("course", course);
  // console.log("prices", prices);
  // console.log("free", free);

  // console.log("startDate", startDate);
  // console.log("endDate", endDate);

  // console.log("timezone", timezone);

  // console.log("startTime", startTime);
  console.log("startDate", startDate);
  console.log("endDate", endDate);

  console.log("timezone", timezone);
  console.log("timezoneOffset", timezoneOffset);

  // console.log("newStartDate", newStartDate);
  // console.log("newEndDate", newEndDate);

  // console.log("startTIme", startTime);

  return (
    <>
      <form onSubmit={submitHandler}>
        <TabsComponent
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabsData}
          isTitleValid={isTitleValid & isEndDateValid}
          isProfessionValid={true}
          disabledTabs={[]}
        >
          <TabPanels m="1.5rem 0 0">
            <TabPanel p={0}>
              <FormControl mb={6}>
                <FormLabel fontSize="sm">{t("course.courseImage")}</FormLabel>
                <ImageUpload image={image} setImage={setImage} />
              </FormControl>

              <FormControlDefault
                type="text"
                isRequired
                label={t("form.title")}
                value={title}
                placeholder={t("placeholder.title")}
                onChange={(e) => {
                  setTitle(e.target.value);
                  setTitleTouched(true);
                }}
                onBlur={() => setTitleTouched(true)}
                isTouched={titleTouched}
                isError={isTitleError}
                w="100%"
                errorMessage={t("formError.titleRequired")}
              />

              <FormControlDatePickerRangeDoubleEdit
                isRequired
                type="date"
                label={t("form.courseDate")}
                placeholder={t("placeholder.startDate")}
                onChange={(start, end) => handleDateRangeChange(start, end)}
                startDate={startDate}
                endDate={endDate}
                onOpen={() => {}}
                w="50%"
                // isTouched={startDate !== "" && endDate !== "" && !isEndDateValid}
                startDateTouched={startDateTouched}
                endDateTouched={endDateTouched}
                isError={isStartDateError || isEndDateError}
                errorMessage={t("formError.courseDateInvalid")}
                setStartDateTouched={setStartDateTouched}
                setEndDateTouched={setEndDateTouched}
                timezone={timezone}
                timezoneOffset={moment.tz(timezone).format("Z")}
              />

              <Flex align="center" justify="space-between" w="100%" gap="1rem">
                <FormControlTimePicker
                  label={t("form.startTime")}
                  value={startTime}
                  onChange={(selectedOption) =>
                    setStartTime(selectedOption?.value)
                  }
                  placeholder={t("placeholder.selectStartTime")}
                />
                <FormControlTimezone
                  label={t("form.timezone")}
                  value={timezone}
                  onChange={(selectedOption) =>
                    setTimezone(selectedOption.value)
                  }
                  placeholder={t("placeholder.selectTimezone")}
                  isRequired={true}
                />
              </Flex>

              <FormControl mb={4}>
                <FormLabel fontSize="sm">
                  {t("form.maximumAttendees")}
                </FormLabel>
                <Flex maxWidth="100%" m="0">
                  <Input
                    maxWidth="80px"
                    type="number"
                    placeholder={t("placeholder.maximumAttendees")}
                    value={maximumAttendees}
                    onChange={(e) => setMaximumAttendees(e.target.value)}
                    size="lg"
                    fontSize="1rem"
                    fontWeight="500"
                    border={`1px solid ${newBorder}`}
                    borderRadius="0.75rem"
                    _focusVisible={{
                      zIndex: "1",
                      borderColor: primary,
                      boxShadow: `0 0 0 1px ${primary}`,
                    }}
                  />
                  <Flex px={8} w="100%" align="center">
                    <Slider
                      defaultValue={60}
                      min={0}
                      max={100}
                      step={1}
                      onChange={(val) => setMaximumAttendees(val)}
                      value={maximumAttendees}
                    >
                      <SliderTrack bg={backgroundLight}>
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={primary} />
                      </SliderTrack>

                      <SliderThumb boxSize={6} />
                    </Slider>
                  </Flex>
                </Flex>
              </FormControl>
            </TabPanel>

            <TabPanel p={0}>
              <FormControlDefault
                type="text"
                isRequired={false}
                label={t("form.courseLocation")}
                value={location}
                placeholder={t("placeholder.courseLocation")}
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                w="100%"
              />

              <HStack gap={4} w="100%">
                <FormControlAddress
                  currentValue={street}
                  label={t("form.address")}
                  onAddressSelect={handleAddressSelect}
                  isRequired={false}
                  errorMessage={t("formError.addressRequired")}
                  w="100%"
                />

                <FormControlDefault
                  type="text"
                  isRequired={false}
                  label={t("form.zip")}
                  value={zip}
                  placeholder={t("placeholder.zip")}
                  onChange={(e) => {
                    setZip(e.target.value);
                  }}
                  w="40%"
                  errorMessage={t("formError.zipRequired")}
                />
              </HStack>

              <HStack gap={4} w="100%">
                <FormControlDefault
                  type="text"
                  isRequired={false}
                  label={t("form.city")}
                  value={city}
                  placeholder={t("placeholder.city")}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  w="50%"
                  errorMessage={t("formError.cityRequired")}
                />

                <FormControlCountry
                  label={t("form.country")}
                  value={country}
                  onChange={(selectedOption) => {
                    setCountry(selectedOption ? selectedOption.value : "");
                  }}
                  isRequired={false}
                  w="50%"
                  errorMessage={t("formError.countryRequired")}
                />
              </HStack>
            </TabPanel>

            <TabPanel p={0}>
              <FormControl mb={4}>
                {!showAddSpeaker && (
                  <FormLabel
                    mt={4}
                    fontSize="sm"
                    display="flex"
                    alignItems="center"
                  >
                    {t("course.speakers")}
                    <Text ml={1} color={textSecondary}>
                      ({speakers.length})
                    </Text>
                  </FormLabel>
                )}

                {speakers.length > 0 ? null : (
                  <Box>
                    {!showAddSpeaker && (
                      <Box
                        p={0}
                        textAlign="center"
                        border={`1px dashed ${newBorder}`}
                        borderRadius="md"
                      >
                        <MiniNoItems
                          title={t("course.noSpeakersCourse")}
                          text={t("course.noSpeakersCourseText")}
                          buttonFunction={handleShowAddSpeaker}
                          buttonLabel={t("course.noSpeakersCourseButtonLabel")}
                        />
                      </Box>
                    )}
                  </Box>
                )}

                <Box>
                  {speakers.length > 0 ? (
                    <Box>
                      {showAddSpeaker && (
                        <FormLabel
                          mt={4}
                          fontSize="sm"
                          display="flex"
                          alignItems="center"
                        >
                          {t("course.speakers")}
                          <Text ml={1} color={textSecondary}>
                            ({speakers.length})
                          </Text>
                        </FormLabel>
                      )}
                      <SpeakerList
                        speakers={speakers}
                        newlyAddedSpeakers={newlyAddedSpeakers}
                        removeSpeakerHandler={removeSpeakerHandler}
                      />
                    </Box>
                  ) : null}

                  {speakers.length > 0 && !showAddSpeaker && (
                    <Box mt={1}>
                      <Button
                        variant="ghost"
                        size="md"
                        fontWeight="600"
                        fontSize="0.875rem"
                        color={textSecondary}
                        onClick={handleShowAddSpeaker}
                      >
                        <Icon
                          as={TbPlus}
                          mr="0.5rem"
                          color={textSecondary}
                          transition="all 0.2s"
                          _hover={{
                            color: textPrimary,
                            cursor: "pointer",
                            transform: "scale(1.2)",
                          }}
                        />
                        {t("course.addNewSpeaker")}
                      </Button>
                    </Box>
                  )}

                  {showAddSpeaker && !showCreateSpeaker && (
                    <FormControl mt={4}>
                      <>
                        <FormLabel fontSize="sm">
                          <Flex justify="space-between">
                            {t("course.addNewSpeaker")}
                          </Flex>
                        </FormLabel>

                        <Flex w="100%" mb={10} flexDirection="column">
                          <Box w="100%">
                            <MultiSpeakerSelectNoInactiveNoCurrent
                              currentOptions={speakers}
                              onChange={addSpeakerHandleNew}
                              value={customSpeakers}
                              placeholderText={t("select.speaker")}
                              title={t("selected.speakers")}
                            />
                          </Box>
                        </Flex>
                      </>
                    </FormControl>
                  )}
                </Box>
              </FormControl>
            </TabPanel>

            <TabPanel p={0}>
              <FormControl>
                <FormLabel fontSize="sm">{t("form.description")}</FormLabel>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                  value={description} // Use your state variable here
                  onEditorChange={(content) => setDescription(content)} // Update your state variable
                />
              </FormControl>
            </TabPanel>
            <TabPanel p={0}>
              <FormControl>
                <FormLabel fontSize="sm">{t("course.courseType")}</FormLabel>
                <Flex mb={4} w="100%" align="center" justify="space-between">
                  <Box w="50%" pr={2}>
                    <Button
                      display="flex"
                      justifyContent="flex-start"
                      textAlign="left"
                      align="center"
                      // border={`1px dashed ${newBorder}`}
                      w="100%"
                      borderRadius="0.75rem"
                      p="2rem 1rem"
                      onClick={() => setFree(false)}
                      bg={!free ? white : white}
                      _hover={{
                        bg: backgroundSky,
                      }}
                      border={
                        !free
                          ? `1px solid ${primary}`
                          : `1px dashed ${newBorder}`
                      }
                    >
                      <Circle
                        h="1rem"
                        w="1rem "
                        bg={white}
                        border={
                          !free
                            ? `1px solid ${primary}`
                            : `1px solid ${newBorder}`
                        }
                      >
                        {!free && <Circle h="0.5rem" w="0.5rem" bg={primary} />}
                      </Circle>
                      <Stack gap={0} pl={4}>
                        <Text
                          fontSize="md"
                          color={textPrimary}
                          fontWeight="500"
                        >
                          {t("course.coursePaid")}
                        </Text>
                        <Text
                          fontSize="sm"
                          color={textSecondary}
                          fontWeight="400"
                        >
                          {t("course.coursePaidText")}
                        </Text>
                      </Stack>
                    </Button>
                  </Box>
                  <Box w="50%" pl={2}>
                    <Button
                      display="flex"
                      justifyContent="flex-start"
                      textAlign="left"
                      align="center"
                      // border={`1px dashed ${newBorder}`}
                      w="100%"
                      borderRadius="0.75rem"
                      p="2rem 1rem"
                      onClick={() => setFree(true)}
                      bg={free ? backgroundSky : white}
                      _hover={{
                        bg: backgroundSky,
                        border: `1px dashed ${newBorder}`,
                      }}
                      border={
                        free
                          ? `1px solid ${primary}`
                          : `1px dashed ${newBorder}`
                      }
                    >
                      <Circle
                        h="1rem"
                        w="1rem "
                        bg={white}
                        border={
                          free
                            ? `1px solid ${primary}`
                            : `1px solid ${newBorder}`
                        }
                      >
                        {free && <Circle h="0.5rem" w="0.5rem" bg={primary} />}
                      </Circle>
                      <Stack gap={0} pl={4}>
                        <Text
                          fontSize="md"
                          color={textPrimary}
                          fontWeight="500"
                        >
                          {t("course.courseFree")}
                        </Text>
                        <Text
                          fontSize="sm"
                          color={textSecondary}
                          fontWeight="400"
                        >
                          {t("course.courseFreeText")}
                        </Text>
                      </Stack>
                    </Button>
                  </Box>
                </Flex>
              </FormControl>
              {!free && (
                <FormControl>
                  <FormLabel fontSize="sm">
                    {t("course.coursePrices")}
                  </FormLabel>

                  <Flex mb={4} direction="column">
                    {prices.map((price) => (
                      <Flex
                        key={price._id}
                        align="center"
                        justify="space-between"
                        mb={4}
                        border={`1px solid ${newBorder}`}
                        borderRadius="0.75rem"
                        bg={backgroundLight}
                      >
                        <Flex
                          align="center"
                          justify="flex-start"
                          borderRadius="0.75rem"
                          h="3rem"
                          w="40%"
                          p="0 1rem"
                          border={`0px solid ${newBorder}`}
                          fontWeight="500"
                          fontSize="0.875rem"
                          color={textSecondary}
                          mr={4}
                        >
                          {price &&
                            price.professionGroup &&
                            price.professionGroup.name}
                        </Flex>
                        <InputGroup w="20%">
                          <InputLeftAddon
                            color={textSecondary}
                            fontWeight="500"
                            children="€"
                            fontSize="0.875rem"
                            borderTopLeftRadius="0.75rem"
                            borderBottomLeftRadius="0.75rem"
                            border="none"
                          />
                          <Input
                            textAlign="left"
                            bg={white}
                            borderRadius="0.75rem"
                            border="none"
                            size="md"
                            mr={1}
                            fontWeight="500"
                            fontSize="1rem"
                            type="number"
                            placeholder="0"
                            value={price.price}
                            onChange={(e) =>
                              updatePrice(
                                price.professionGroup._id,
                                parseInt(e.target.value)
                              )
                            }
                          />
                        </InputGroup>
                      </Flex>
                    ))}
                  </Flex>
                </FormControl>
              )}
            </TabPanel>
          </TabPanels>

          <Flex
            mt={4}
            direction="column"
            borderTop={`1px solid ${newBorder}`}
            p="2rem 0 0"
          >
            <Flex justify="space-between">
              <Tooltip
                bg={white}
                borderRadius="1rem"
                p="1rem"
                label={
                  <Flex
                    direction="column"
                    p="0rem"
                    justify="center"
                    textAlign="center"
                  >
                    {isTitleError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.titleRequired")}
                      </Text>
                    )}
                    {isStartDateError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.courseDateInvalid")}
                      </Text>
                    )}
                    {isDateError && (
                      <Text fontSize="13px" color="#e53e3e" whiteSpace="nowrap">
                        * {t("formError.courseDateInvalid")}
                      </Text>
                    )}
                  </Flex>
                }
                isDisabled={!isContinueDisabled}
                hasArrow
                placement="top"
                fontSize="md"
              >
                <Button
                  minWidth="10rem"
                  type="submit"
                  size="lg"
                  bg={primary}
                  color={white}
                  fontSize="md"
                  borderRadius="10rem"
                  isDisabled={isContinueDisabled}
                  _hover={{
                    bg: shade(0.3, primary),
                  }}
                  ml={4}
                >
                  {t("course.updateCourseButtonLabel")}
                </Button>
              </Tooltip>

              <Flex align="center">
                {activeTab > 0 && (
                  <Button
                    minWidth="8rem"
                    mr={0}
                    bg={backgroundLight}
                    border={`1px solid ${newBorder}`}
                    size="lg"
                    color={textSecondary}
                    fontSize="md"
                    fontWeight="500"
                    borderRadius="10rem"
                    onClick={() => setActiveTab(activeTab - 1)}
                    isDisabled={isContinueDisabled}
                    _hover={{
                      bg: shade(0.1, backgroundLight),
                    }}
                  >
                    {t("common.back")}
                  </Button>
                )}

                {activeTab < 1 && (
                  <Tooltip
                    bg={white}
                    borderRadius="1rem"
                    p="1rem"
                    label={
                      <Flex
                        direction="column"
                        p="0rem"
                        justify="center"
                        textAlign="center"
                      >
                        {isTitleError && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.titleRequired")}
                          </Text>
                        )}
                        {isStartDateError && (
                          <Text
                            fontSize="13px"
                            color="#e53e3e"
                            whiteSpace="nowrap"
                          >
                            * {t("formError.courseDateInvalid")}
                          </Text>
                        )}
                      </Flex>
                    }
                    isDisabled={!isContinueDisabled}
                    hasArrow
                    placement="top"
                    fontSize="md"
                  >
                    <Button
                      minWidth="8rem"
                      ml={4}
                      bg={backgroundLight}
                      border={`1px solid ${newBorder}`}
                      size="lg"
                      color={textSecondary}
                      fontSize="md"
                      fontWeight="500"
                      borderRadius="10rem"
                      onClick={() => setActiveTab(activeTab + 1)}
                      isDisabled={isContinueDisabled}
                      _hover={{
                        bg: shade(0.1, backgroundLight),
                      }}
                    >
                      {t("common.continue")}
                    </Button>
                  </Tooltip>
                )}
              </Flex>
            </Flex>
          </Flex>
        </TabsComponent>
      </form>
    </>
  );
};

export default EditCourse;
